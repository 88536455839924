import Navbar from "./Navbar";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import { useMediaQuery } from "@mui/material";
import { useState, useRef } from "react";
import Input from "../core/components/InputV2";
import MetaTags from "../core/components/Meta/Meta";
import { aboutUsMeta } from "../core/utils/meta";

const AboutUs = () => {
    const ref = useRef(null);
    const isMobile = useMediaQuery('(max-width:700px)');
    const isSmallHeight = useMediaQuery('(max-height:560px)');
    const [email, setEmail] = useState("");

    return (
        <>
            <MetaTags metaProps={aboutUsMeta} />
            <Navbar className="bg-clean" />
            <div className={`flex flex-col w-full h-full p-3 pt-8  md:p-14  ${isSmallHeight ? "min-h-[70vh]" : "min-h-[90vh]"}  bg-clean relative `}>
                <div className="relative z-10">
                    <div className=" bg-ash font-roboto mb-4 md:mb-[24px] p-1 w-[40%] text-xs md:text-md md:w-36 text-center font-medium rounded-md border-b border-gradient-dark  inline-block">ABOUT WOKELO</div>
                    <h1 className="font-aeonik text-carbon text-[34px] md:text-4xl lg:text-5xl xl:text-6xl leading-[100%] font-semibold md:mb-[39px] w-full md:w-[95%] lg:w-[80%]">We envision a world where human ingenuity is amplified by ethical and secure AI</h1>
                    <div className="flex flex-col font-roboto font-normal text-sm md:text-base text-carbon !leading-[130%] w-full md:w-[40%] my-2">
                        <p className="my-4">At Wokelo, we’ve built a new kind of AI-powered infrastructure designed to fuel the knowledge economy</p>
                        <p>Our intelligent decision support system transforms complex cognitive workloads into actionable insights—tailored for investment and strategy professionals to make smarter, faster decisions with confidence</p>
                    </div>
                </div>
                <div className={`absolute bottom-0 -z-1 ${isSmallHeight ? "hidden" : "block"} ${isMobile ? "right-10 left-10" : "md:right-20 lg:right-40"}`}>
                    <img
                        src="/AboutUs/globe.svg"
                        alt=""
                        className="h-auto width-auto"
                    />
                </div>

            </div>
            <div className="bg-shade pb-9">
                <div className="flex flex-col w-full h-full p-4 pt-8  md:p-14 min-h-[90vh] bg-shade relative">
                    <div className=" bg-ash font-roboto mb-8 p-1 w-[60%] text-xs md:text-md md:w-48 text-center font-medium rounded-md border-b border-gradient-dark  inline-block">WHY WE STARTED WOKELO</div>
                    <h1 className="font-aeonik text-clean text-2xl md:text-5xl lg:text-6xl font-medium w-full md:w-[80%]">Wokelo was founded to redefine how investors harness knowledge for smarter decision-making</h1>
                    <p className="text-clean text-base md:text-[21px] font-normal font-roboto my-10 w-full md:w-[55%]">The cost of coverage gaps and siloed data is more than just expensive subscriptions and overworked teams—it’s about lost opportunities:</p>

                    <div className={`flex ${isMobile ? "flex-col" : " flex-row"}   gap-8 my-2`}>
                        <div
                            className="border-l-2 flex flex-col justify-between gap-8 md:gap-16 px-3 w-full md:w-[33.33%]"
                            style={{
                                borderImage: 'linear-gradient(to bottom, #0C92B4, #A1CCA5) 1',
                            }}
                        >
                            <img
                                src="/AboutUs/icon1.svg"
                                alt=""
                                className="w-10 h-10"
                            />
                            <p className="font-aeonik text-clean font-medium text-lg md:text-[24px] w-[90%] md:w-[80%]">Blind spots that impact critical decisions</p>
                        </div>
                        <div
                            className="border-l-2 flex flex-col justify-between gap-8 md:gap-16 px-3 w-full md:w-[33.33%]"
                            style={{
                                borderImage: 'linear-gradient(to bottom, #0C92B4, #A1CCA5) 1',
                            }}
                        >
                            <img
                                src="/AboutUs/icon3.svg"
                                alt=""
                                className="w-10 h-10"
                            />
                            <p className="font-aeonik text-clean text-lg font-medium md:text-[24px] w-[90%] md:w-[80%]">Time drained from strategic stakeholder engagement</p>
                        </div>
                        <div
                            className="border-l-2 flex flex-col justify-between gap-8 md:gap-16 px-3 w-full md:w-[33.33%]"
                            style={{
                                borderImage: 'linear-gradient(to bottom, #0C92B4, #A1CCA5) 1',
                            }}
                        >
                            <img
                                src="/AboutUs/icon2.svg"
                                alt=""
                                className="w-10 h-10"
                            />
                            <p className="font-aeonik text-clean text-lg md:text-[24px] font-medium w-[90%] md:w-[95%]">Limited ability to explore new markets and emerging opportunities</p>
                        </div>
                    </div>
                </div>
                <hr className="w-full h-[1px] border-0 bg-iron" />
                <p className="font-roboto px-4 md:px-14 text-clean font-normal text-sm md:text-[18px] !leading-[140%] w-full md:w-[50%] pt-4">Wokelo eliminates research bottlenecks, delivering high-fidelity insights so professionals can focus on what matters most—strategy, execution, and growth.</p>
            </div>

            <div className="flex flex-col w-full h-full p-3 py-8  md:p-14 min-h-[90vh] bg-clean relative ">
                <h1 className="font-aeonik text-carbon text-2xl md:text-5xl lg:text-7xl font-medium w-full md:w-[80%]">We challenge the confined, linear, and fragmented nature of investment research</h1>

                <div className={`flex ${isMobile ? "flex-col" : "flex-row"} justify-between pt-4`}>
                    <div className="flex flex-col justify-between font-normal gap-10 font-roboto text-sm md:text-base text-circuit w-full md:w-[40%] my-2">
                        <div>
                            <p className="mb-4">High-stakes investment research demands precision and depth that Large Language Models (LLMs) alone can’t provide as mere summarization tools.</p>
                            <p>Wokelo goes beyond, to integrate domain-specific LLMs and agents into a full-stack intelligence platform that mirrors
                                human cognition at all steps of the research. From data selection and synthesis to instant report
                                generation, you get end-to-end workflow automation and finished deliverables.
                            </p>
                        </div>
                        <div>
                            <hr className=" bg-gradient-to-r from-gradient-light to-gradient-dark w-full h-[1px] border-0" />
                            <h2 className="font-roboto-mono font-semibold  text-base md:text-lg my-2 text-carbon">RESULT?</h2>
                            <p className="font-roboto text-circuit text-[13px] md:text-base w-[80%] ">High-fidelity insights, verified data sources, built-in fact-checks, and enterprise-grade security.</p>
                        </div>

                    </div>
                    <div className={` ${isMobile ? "right-10 left-10" : " md:right-20 lg:right-40"}  `}>
                        <img
                            src="/AboutUs/window.svg"
                            alt=""
                            className="h-auto width-auto"
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full h-full p-4 py-4 pb-8 md:px-14 md:pt-14 md:pb-[40px] min-h-[90vh] bg-shade relative ">
                <h1 className="font-aeonik text-clean text-2xl md:text-5xl lg:text-7xl font-medium w-full md:w-[85%] 2xl:w-[65%]">Why top-tier investment and strategy organizations trust Wokelo</h1>

                <div className={`flex ${isMobile ? "flex-col" : "flex-row"} justify-between pt-2 md:pt-4`}>
                    <div className="flex flex-col font-normal  font-roboto text-sm md:text-base text-clean w-full md:w-[40%] py-3 ">

                        <p className="my-2">With deep expertise in strategy, venture capital, and M&A, Wokelo’s leadership, core team, and advisors understand first-hand the
                            complexities of traditional research—and the limitations of fragmented, point-based solutions.
                        </p>
                        <p className="my-2">Built to meet the rigorous standards of the industry, Wokelo has earned the trust of global leaders in consulting, investment banking, venture capital,
                            and private equity—helping them make faster, smarter, and data-driven decisions.
                        </p>

                    </div>

                    <div className={` flex items-end  `}>
                        <div className={`w-full flex gap-14 ${isMobile ? 'flex-col' : ''} sm:gap-4 md:gap-8 lg:gap-14 `}>

                            <div className="flex flex-col">
                                <picture className="border-l border-l-metal pl-4">
                                    <img
                                        src="HumanProd/40+.svg"
                                        alt="Hero"
                                        className="w-[82px] sm:w-[70px] md:w-[75px] lg:w-[82px] h-auto my-3"
                                    />
                                    <p className="text-mint font-aeonik text-[14px] mt-2 ">Enterprise customers</p>
                                </picture>
                            </div>

                            <div className="flex flex-col">
                                <picture className="border-l border-l-metal pl-4">
                                    <img
                                        src="HumanProd/5000+.svg"
                                        alt="Hero"
                                        className="w-[141px] sm:w-[110px] md:w-[120px] lg:w-[141px] h-auto my-3"
                                    />
                                    <p className="text-mint font-aeonik text-[14px] mt-2 ">Unique sectors</p>
                                </picture>
                            </div>

                            <div className="flex flex-col">
                                <picture className="border-l border-l-metal pl-4">
                                    <img
                                        src="HumanProd/25+.svg"
                                        alt="Hero"
                                        className="w-[78px] sm:w-[65px] md:w-[70px] lg:w-[78px] h-auto my-3"
                                    />
                                    <p className="text-mint font-aeonik text-[14px] mt-2">Research workflows curated</p>
                                </picture>
                            </div>


                        </div>
                    </div>
                </div>


                <section className={`flex flex-col border-t border-metal mt-14`}>
                    <div className="flex flex-col lg:flex-row justify-between gap-5 md:gap-8 pt-10">
                        <div className="w-full lg:w-[14%] flex justify-start items-center">
                            <p className="text-[14px] text-mint font-aeonik md:text-[16px]">Backed by:</p>
                        </div>
                        <div
                            className={`w-full mb-2 md:mb-[20px]  flex flex-row flex-wrap gap-8 space-y-2 md:gap-6 lg:gap-10  lg:justify-end items-center `}
                        >
                            <div>
                                <picture>
                                    <source
                                        srcSet="/ticker/geek_ventures.svg"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/ticker/geek_ventures.svg"
                                        alt="Hero"
                                        className="h-[20px] md:h-auto my-auto"
                                    />
                                </picture>
                            </div>
                            <div>
                                <picture>
                                    <source
                                        srcSet="/ticker/Rebellion_ventures.svg"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/ticker/Rebellion_ventures.svg"
                                        alt="Hero"
                                        className="h-[20px] md:h-auto my-auto"
                                    />
                                </picture>
                            </div>
                            <div>
                                <picture>
                                    <source srcSet="/ticker/Array_vc.svg" type="image/webp" />
                                    <img
                                        src="/ticker/Array_vc.svg"
                                        alt="Hero"
                                        className="h-[20px] w-[100px] md:w-auto md:h-auto my-auto"
                                    />
                                </picture>
                            </div>
                            <div>
                                <picture>
                                    <source
                                        srcSet="/ticker/untapped.svg"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/ticker/untapped.svg"
                                        alt="Hero"
                                        className="h-[20px] w-[100px] md:w-auto md:h-auto my-auto"
                                    />
                                </picture>
                            </div>
                            <div>
                                <picture>
                                    <source
                                        srcSet="/ticker/kpmg-solutions.svg"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/ticker/kpmg-solutions.svg"
                                        alt="Hero"
                                        className="h-[20px] md:h-auto my-auto"
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </section>


            </div>

            <div className={`flex ${isMobile ? "flex-col" : "flex-row"} xl:justify-between w-full h-full p-3 py-8  md:p-14  bg-clean relative `}>
                <div className="md:w-1/2">
                    <h1 className="font-aeonik text-carbon text-2xl md:text-5xl lg:text-6xl font-semibold w-full md:w-[90%]">Join us in our mission to revolutionize investment research</h1>
                    <p className="mb-4 pt-4 font-roboto text-sm md:text-base text-metal">Say hello to the future of work, welcome to Wokelo.</p>

                </div>

                <div className="w-full shrink-0 md:w-1/2 xl:w-1/3  h-20">
                    <Input
                        ref={ref}
                        className="relative flex items-end bg-input-bg justify-between w-full max-w-md p-3 border border-input-border rounded-md"
                        inputClassName="w-full px-1 md:px-3 py-1 bg-input-bg font-roboto focus:outline-none text-xs md:text-[16px] focus:ring-0  rounded-md"
                        buttonClass='absolute  right-1.5 top-1.5 bottom-1.5 px-2 md:px-3 font-roboto-mono text-[10px] md:text-[14px] bg-nano-light text-carbon font-medium rounded-[4px] flex items-center justify-center'
                        placeholder="Your e-mail address"
                        variant="nano"
                        value={email}
                        isMobile={isMobile}
                        buttonText={["BOOK A DEMO", "BOOK A DEMO"]}
                        onInput={(e) => {
                            setEmail(e.currentTarget.value);
                        }}
                    />
                </div>

            </div>


            {isMobile ? <MobileFooter /> : <DesktopFooter />}
        </>
    )
}

export default AboutUs;