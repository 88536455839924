import { useState } from "react";
import { DashMenuIcon } from "../core/icons/icons";
import { Link } from "react-router-dom";

const Navbar = (
    {
        className = ''
    }: {
        className?: string
    }
) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className={`flex items-center justify-between px-2 md:px-4 lg:px-10 py-4 relative w-full ${className}`}>
            {/* Logo */}
            <div className="w-auto">
                <Link to={'/'} className="cursor-pointer">
                    <img src="/Logo/logo.svg"
                        alt="Logo"
                        className="max-w-[70%] mx-2 lg:mx-10" />
                </Link>
            </div>

            {/* Desktop Menu */}
            <div className="hidden lg:flex space-x-6 uppercase text-carbon">
                <a href="/#product" className="font-mono text-sm lg:text-md ">Products</a>
                <a href="/#howitworks" className="font-mono text-sm lg:text-md whitespace-nowrap">How It Works</a>
                <a href="/#solutions" className="font-mono text-sm lg:text-md">Solutions</a>
                <a href="/about-us" className="font-mono text-sm lg:text-md">Company</a>
                <a href="https://insights.wokelo.ai/" target="_blank" className="font-mono text-sm lg:text-md">Insights</a>
            </div>

            {/* Buttons */}
            <div className="flex items-center font-mono text-carbon">
                <a href="https://app.wokelo.ai"
                    target="_blank"
                    rel="noopener noreferrer" className="hidden lg:block text-sm lg:text-md uppercase px-7 whitespace-nowrap">Log In</a>
                <a href="/contact-us" rel="noopener noreferrer">
                    <button className="hidden lg:block bg-nano-light px-5 py-2 font-medium whitespace-nowrap rounded-md"
                    >GET STARTED</button>
                </a>
            </div>

            {/* Mobile Menu Button */}
            <div className="lg:hidden flex items-center space-x-3 font-mono text-carbon">
            <a href="/contact-us" rel="noopener noreferrer">
                <button className="bg-nano-light px-5 py-2 text-xs font-medium whitespace-nowrap rounded-md">GET STARTED</button>
            </a>
                <button onClick={() => setIsOpen(!isOpen)} className="pr-2">
                    <DashMenuIcon height={20} width={20} />
                </button>
            </div>

            {/*  Mobile Menu */}
            {isOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-r from-gray-100 to-white z-50 flex flex-col items-center justify-center p-4 lg:hidden">
                    <button className="absolute top-5 right-5 text-2xl" onClick={() => setIsOpen(false)}>✕</button>
                    <div className="flex flex-col items-center space-y-6 text-black text-3xl mb-52">
                        <a href="/#product" className="" onClick={() => setIsOpen(false)}>Products</a>
                        <a href="/#howitworks" className="" onClick={() => setIsOpen(false)}>How It Works</a>
                        <a href="/#solutions" className="" onClick={() => setIsOpen(false)}>Solutions</a>
                        <a href="/about-us" className="" onClick={() => setIsOpen(false)}>Company</a>
                        <a href="https://insights.wokelo.ai/" target="_blank" className="">Insights</a>
                    </div>
                    <div className="absolute bottom-20 flex justify-center items-center space-x-12 w-full">
                        <div>
                            <a href="https://app.wokelo.ai"
                                target="_blank"
                                rel="noopener noreferrer" className="text-lg">Login</a>
                        </div>
                        <div>
                            <a href="/contact-us" rel="noopener noreferrer">
                                <button className="bg-black text-white text-lg px-6 py-2 rounded-full">Get Started</button>
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;