import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material'
import { useSearchParams } from 'react-router-dom';;
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';
import Navbar from './Navbar';

const LegalPages = () => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const [searchParams, setSearchParams] = useSearchParams();
  let initialTab = searchParams.get('tab') || 'terms';
  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <Navbar className='bg-white-bg' />
      <div className="flex flex-col md:flex-row w-full bg-white-bg h-full">
        {/* Mobile Tabs */}
        <div className="border-t mt-4 border-outline-border"></div>
        <div className="flex md:hidden w-full ">
          <button
            className={`py-4 px-3 text-xs md:text-sm font-roboto-mono flex-1 text-center ${activeTab === 'terms' ? 'text-black bg-white' : 'text-gray-500 font-light'} font-medium`}
            onClick={() => handleTabClick('terms')}
          >
            TERMS & CONDITIONS
          </button>
          <button
            className={`py-4 px-3 text-xs md:text-sm  font-roboto-mono flex-1 text-center ${activeTab === 'privacy' ? 'text-black bg-white' : 'text-gray-500 font-light'} font-medium`}
            onClick={() => handleTabClick('privacy')}
          >
            PRIVACY POLICY
          </button>
        </div>
        <div className="border-t border-outline-border"></div>


        {/* Desktop Sidebar */}
        <div className="hidden md:block md:w-80 md:flex-shrink-0">
          <div className="p-12">
            <button
              className={`font-roboto-mono block text-left w-full font-medium text-md ${activeTab === 'terms' ? 'text-black' : 'text-gray-500'}`}
              onClick={() => handleTabClick('terms')}
            >
              TERMS AND CONDITIONS
            </button>
            <div className="border-t my-4 border-nano-light"></div>
            <button
              className={`font-roboto-mono block text-left w-full font-medium text-md ${activeTab === 'privacy' ? 'text-black' : 'text-gray-500'}`}
              onClick={() => handleTabClick('privacy')}
            >
              PRIVACY POLICY
            </button>
            <div className="border-b my-4  border-nano-light"></div>
          </div>
        </div>

        {/* Content  */}
        <div className="flex-1 p-6 md:p-8 md:ml-24  border-l  border-nano-light mb-5 lg:mb-10 font-aeonik">
          {activeTab === 'terms' && (
            <div className="space-y-4 max-w-3xl">
              <div>
                <h1 className="text-3xl  md:text-4xl lg:text-6xl  text-carbon font-semibold">Terms of service</h1>
                <p className="text-sm md:text-base font-aeonik  text-metal mt-2 mb-6 lg:mb-10">Last updated: February 12, 2025</p>
              </div>
              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Agreement to terms</h2>

              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  These Terms of Service constitute a legally binding agreement made between
                  you, whether personally or on behalf of an entity ("you") and Wokelo Inc., doing
                  business as Wokelo AI ("Wokelo AI", "we", "us", or "our"), concerning your access
                  to and use of the wokelo.ai website as well as any other media form, media
                  channel, mobile website or mobile application related, linked, or otherwise
                  connected thereto (collectively, the "Site"). We are registered in Washington,
                  United States and have our registered office at 92 Lenora Street, Seattle,
                  Washington 98121. You agree that by accessing the Site, you have read,
                  understood, and agree to be bound by all of these Terms of Service. IF YOU DO
                  NOT AGREE WITH ALL OF THESE TERMS OF SERVICE, THEN YOU ARE
                  EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE
                  USE IMMEDIATELY.
                </p>

                <p className="text-metal font-roboto text-sm sm:text-base">
                  If you have another written agreement between us and you governing your use
                  of Wokelo products or services, the other written agreement will control over
                  these Terms of Service to the extent of any conflict between the two with
                  respect to the use of the Wokelo products and services covered by the other
                  written agreement.
                </p>

                <p className="text-metal font-roboto text-sm sm:text-base">
                  Supplemental terms and conditions or documents that may be posted on the Site
                  from time to time are hereby expressly incorporated herein by reference. We
                  reserve the right, in our sole discretion, to make changes or modifications
                  to these Terms of Service at any time and for any reason. We will alert you about
                  any changes by updating the "Last updated” date of these Terms of Service, and you
                  waive any right to receive specific notice of each such change. Please ensure that
                  you check the applicable Terms of Service every time you use our Site so that you
                  understand which Terms of Service apply. You will be subject to, and will be deemed
                  to have been made aware of and to have accepted, the changes in any revised Terms of
                  Service by your continued use of the Site after the date such revised Terms of Service are posted.
                </p>

                <p className="text-metal font-roboto text-sm sm:text-base">
                  The information provided on the Site is not intended for distribution to or use by any person or
                  entity in any jurisdiction or country where such distribution or use would be contrary to law or
                  regulation or which would subject us to any registration requirement within such jurisdiction or country.
                  Accordingly, those persons who choose to access the Site from other locations do so on their own initiative
                  and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                </p>

                <p className="text-metal font-roboto text-sm sm:text-base">
                  The Site is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability
                  Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected
                  to such laws, you may not use this Site. You may not use the Site in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                </p>

                <p className="text-metal font-roboto text-sm sm:text-base">
                  The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or
                  register for the Site.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Intellectual property rights</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality,
                  software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and
                  the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us,
                  and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition
                  laws of the United States, international copyright laws, and international conventions. The Content and the Marks are
                  provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of
                  Service, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted,
                  publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                  purpose whatsoever, without our express prior written permission.
                </p>

                <p className="text-metal font-roboto text-sm sm:text-base">
                  Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or
                  print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use.
                  We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">User representations</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete;
                  (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal
                  capacity and you agree to comply with these Terms of Service; (4) you are not a minor in the jurisdiction in which you reside; (5) you will
                  not access the Site through automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the Site for any illegal or
                  unauthorized purpose; and (7) your use of the Site will not violate any applicable law or regulation. If you provide any information that is untrue, inaccurate,
                  not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">User registration</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password.
                  We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene,
                  or otherwise objectionable.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Fees and payment</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You may be required to purchase or pay a fee to access some of our services. You agree to provide current, complete, and accurate purchase and account
                  information for all purchases made via the Site. You further agree to promptly update account and payment information, including email address, payment
                  method, and payment card expiration date, so that we can complete your transactions and contact you as needed. We bill you through an online billing account
                  for purchases made via the Site. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments
                  shall be in USD.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us to charge your chosen payment provider for any
                  such amounts upon making your purchase.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We also reserve the right
                  to refuse any order placed through the Site.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Cancellation</h2>
              <div className="space-y-4 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You can cancel your subscription at any time. Your cancellation will take effect at the end of the current paid term.
                  If you are unsatisfied with our services, please email us at{" "}
                  <span className="font-bold text-shade">support@wokelo.ai</span>.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Prohibited activities</h2>
              <div className="space-y-4 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You may not access or use the Site for any purpose other than that for which we make the Site available.
                  The Site may not be used in connection with any commercial endeavours except those that are specifically endorsed or
                  approved by us. As a user of the Site, you agree not to:
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection,
                  compilation, database, or directory without written permission from us.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.

                  Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of
                  any Content or enforce limitations on the use of the Site and/or the Content contained therein.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Make improper use of our support services or submit false reports of abuse or misconduct.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Use the Site in a manner inconsistent with any applicable laws or regulations. Engage in unauthorized framing of or linking to the Site.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming
                  (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters,
                  or interferes with the use, features, functions, operation, or maintenance of the Site.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data
                  gathering and extraction tools.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Delete the copyright or other proprietary rights notice from any Content. Attempt to impersonate another user or person or use the username of another user.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without
                  limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection
                  mechanisms” or “pcms”).
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the Site.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion of the Site. Copy or adapt the Site's software, including but
                  not limited to Flash, PHP, HTML, JavaScript, or other code. Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software
                  comprising or in any way making up a part of the Site.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation,
                  any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Use a buying agent or purchasing agent to make purchases on the Site. Make any unauthorized use of the Site, including collecting usernames and/or email addresses of
                  users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any revenue-generating endeavour or commercial enterprise.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">User generated contributions</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  The Site does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute,
                  or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or
                  personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Site and through third-party websites. As
                  such, any Contributions you transmit may be treated in accordance with the Site Privacy Notice. When you create or make available any Contributions, you thereby represent
                  and warrant that:
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Contribution license</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You and the Site agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Notice and your
                  choices (including settings).
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you. We do not assert
                  any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with
                  your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Site. You are solely responsible for your
                  Contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Submissions</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Site ("Submissions") provided by you to us are
                  non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use
                  and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to
                  any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be
                  no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">U.S. Government rights</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Our services are “commercial items” as defined in Federal Acquisition Regulation (“FAR”) 2.101. If our services are acquired by or on behalf of any agency not within the Department
                  of Defence (“DOD”), our services are subject to the terms of these Terms of Service in accordance with FAR 12.212 (for computer software) and FAR 12.211 (for technical data).
                  If our services are acquired by or on behalf of any agency within the Department of Defence, our services are subject to the terms of these Terms of Service in accordance with
                  Defence Federal Acquisition Regulation (“DFARS”) 227.7202-3. In addition, DFARS 252.227-7015 applies to technical data acquired by the DOD. This U.S. Government Rights clause
                  is in lieu of, and supersedes, any other FAR, DFARS, or other clause or provision that addresses government rights in computer software or technical data under these Terms of
                  Service.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Site management</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Service; (2) take appropriate legal action against anyone who,
                  in our sole discretion, violates the law or these Terms of Service, including without limitation, reporting such user to law enforcement authorities; (3) in our sole
                  discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions
                  or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that
                  are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate
                  the proper functioning of the Site.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Term and termination</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  These Terms of Service shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF SERVICE, WE RESERVE THE RIGHT TO,
                  IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                  NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY APPLICABLE LAW OR REGULATION.
                  WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                  DISCRETION.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the
                  name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate
                  legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Modifications and interruptions</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no
                  obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not
                  be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site,
                  resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for
                  any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site
                  during any downtime or discontinuance of the Site. Nothing in these Terms of Service will be construed to obligate us to maintain and support the Site or to supply any
                  corrections, updates, or releases in connection therewith.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Governing law</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  These Terms of Service and your use of the Site are governed by and construed in accordance with the laws of the State of Washington applicable to agreements made and to
                  be entirely performed within the State of Washington, without regard to its conflict of law principles.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Dispute resolution</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Any legal action of whatever nature brought by either you or us (collectively, the “Parties” and individually, a “Party”) shall be commenced or prosecuted in
                  the state and federal courts located in USA, Seattle, Washington, and the Parties hereby consent to, and waive all defences of lack of personal jurisdiction and forum
                  non conveniens with respect to venue and jurisdiction in such state and federal courts. Application of the United Nations Convention on Contracts for the International
                  Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Terms of Service. In no event shall any claim, action, or proceeding
                  brought by either Party related in any way to the Site be commenced more than one (1) years after the cause of action arose.
                </p>
              </div>


              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Corrections</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information.
                  We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Disclaimer</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
                  BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S
                  CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
                  AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                  USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
                  FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS
                  IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
                  THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY
                  HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                  MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
                  ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Limitations of liability</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
                  OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
                  OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING
                  OR $100.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Indemnification</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees,
                  from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
                  (1) use of the Site; (2) breach of these Terms of Service; (3) any breach of your representations and warranties set forth in these Terms of Service; (4) your violation
                  of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site with
                  whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any matter
                  for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify
                  you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">User data</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site.
                  Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using
                  the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from
                  any such loss or corruption of such data.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Electronic communications, transactions, and signatures</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that
                  all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such
                  communication be in writing.
                </p>
                <p className="text-metal font-roboto text-sm sm:text-base">
                  YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                  INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
                  jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than
                  electronic means.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">California users and residents</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department
                  of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Cookies</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  By using this service, you agree to the use of necessary authentication cookies that are essential for the proper functioning of our platform. These cookies help us secure
                  your account and enhance your user experience. Continued use of our services constitutes your acceptance of this policy.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl  font-semibold text-black mb-4 mt-8">Miscellaneous</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  Without limiting any other written agreement between you and us, as described in Section 1 above, these Terms of Service and any policies or operating rules posted by us on the Site or
                  in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of
                  Service shall not operate as a waiver of such right or provision. These Terms of Service operate to the fullest extent permissible by law. We may assign any or all of our
                  rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable
                  control. If any provision or part of a provision of these Terms of Service is determined to be unlawful, void, or unenforceable, that provision or part of the provision is
                  deemed severable from these Terms of Service and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment
                  or agency relationship created between you and us as a result of these Terms of Service or use of the Site. You agree that these Terms of Service will not be construed
                  against us by virtue of having drafted them. You hereby waive any and all defences you may have based on the electronic form of these Terms of Service and the lack of signing
                  by the parties hereto to execute these Terms of Service.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-aeonik font-semibold text-black mb-4 mt-8">Contact us</h2>
              <div className="space-y-3 pb-4">
                <p className="text-metal font-roboto text-sm sm:text-base">
                  In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:
                </p>
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">Wokelo Inc</span>. <br />
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">92 Lenora Street, Seattle, Washington 98121</span>. <br />
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">United States</span>. <br />
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">support@wokelo.ai</span>. <br />
              </div>

            </div>
          )}

          {activeTab === 'privacy' && (
            <div className="space-y-4 max-w-3xl">
              <div>
                <h1 className="text-3xl  md:text-4xl lg:text-6xl font-aeonik  text-carbon font-semibold">Privacy Notice</h1>
                <p className="text-sm md:text-base font-aeonik  text-metal mt-2 mb-6 lg:mb-10">Last updated: February 12, 2025</p>
              </div>
              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Agreement to terms</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  This privacy notice for Wokelo Inc. (doing business as Wokelo AI)
                  (<span className="font-bold">"Wokelo AI," "we," "us," or "our"</span>), describes how and why we might collect, store,
                  use, and/or share (<span className="font-bold">"process"</span>) your information when you use our services (<span className="font-bold">"Services"</span>), such as when you:
                  Visit our website at{" "}
                  <a href="https://wokelo.ai" className="font-bold underline">https://wokelo.ai</a>, or
                  any website of ours that links to this privacy notice. Engage with us in other related ways, including any sales, marketing, or events.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Questions or concerns?</span> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices,
                  please do not use our Services. If you still have any questions or concerns, please contact us at support@wokelo.ai.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  If you have another written agreement between us and you governing your use of Wokelo products or services, the other written agreement will control over this privacy notice to the
                  extent of any conflict between the two with respect to the use of the Wokelo products and services covered by the other written agreement.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">What information do we collect?</h2>
              <h2 className="text-base md:text-lg  font-roboto-mono font-bold text-black mb-2  md:mb-6  mt-2  md:mt-6">PERSONAL INFORMATION YOU DISCLOSE TO US</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span> We collect personal information that you provide to us.
                  We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services,
                  when you participate in activities on the Services, or otherwise when you contact us.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Personal Information Provided by You.</span>The personal information that we collect depends on the context of your interactions with us and the Services,
                  the choices you make, and the products and features you use. The personal information we collect may include the following: names, email addresses, job titles, usernames,
                  passwords, contact preferences, debit/credit card numbers, contact or authentication data, billing addresses
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Sensitive Information.</span>We do not process sensitive information.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Payment Data.</span>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument.
                  <br />
                  All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                </p>
                <h2 className="text-base md:text-lg  font-roboto-mono font-bold text-black mb-2  md:mb-6  mt-2  md:mt-6">INFORMATION AUTOMATICALLY COLLECTED</h2>

                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically
                  when you visit our Services.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information)
                  but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name,
                  country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and reporting purposes.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  The information we collect includes:
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our
                  Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and
                  settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions
                  you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">How do we process your information?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention,
                  and to comply with law. We may also process your information for other purposes with your consent.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</span>
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">To facilitate account creation and authentication and otherwise manage user accounts. </span>We may process your information so you can create and log in to your account,
                  as well as keep your account in working order.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">To save or protect an individual's vital interest.</span>We may process your information when necessary to save or protect an individual's vital interest,
                  such as to prevent harm.
                </p>


              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">What legal base do we rely on to process your information?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short: </span>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law,
                  like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our
                  legitimate business interests.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">If you are located in the EU or UK, this section applies to you.</span>
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the
                  following legal bases to process your personal information:
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Consent.</span> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can
                  withdraw your consent at any time. Click here to learn more.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Legal Obligations.</span> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or
                  regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Vital Interests.</span>  We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations
                  involving potential threats to the safety of any person.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">If you are located in Canada, this section applies to you.</span>
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.
                  <br />
                  In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example: If collection is clearly in the interests of an
                  individual and consent cannot be obtained in a timely way For investigations and fraud detection and prevention For business transactions provided certain conditions are met
                  If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim For identifying injured, ill, or deceased persons and
                  communicating with next of kin If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse If it is reasonable to expect collection
                  and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an
                  greement or a contravention of the laws of Canada or a province If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the
                  production of records If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which
                  the information was produced If the collection is solely for journalistic, artistic, or literary purposes If the information is publicly available and is specified
                  by the regulations
                </p>

              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">When and with whom do we share your personal information?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short: </span> We may share information in specific situations described in this section and/or with the following third parties.We may need to share
                  your personal information in the following situations:
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing,
                  or acquisition of all or a portion of our business to another company.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">How long do we keep your information?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise
                  required by law.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by
                  law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than six (6) months
                  past the termination of the user's account.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example,
                  because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing
                  until deletion is possible.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">How do we keep your information safe?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span> We aim to protect your personal information through a system of organizational and technical security measures.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process.
                  However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed
                  to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly
                  collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our
                  Services is at your own risk. You should only access the Services within a secure environment.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Do we collect information for minors?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span> We do not knowingly collect data from or market to children under 18 years of age.We do not knowingly solicit data
                  from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                  minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected,
                  we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected
                  from children under age 18, please contact us at support@wokelo.ai.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">What are your privacy rights</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short:</span>  In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have
                  rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.In
                  some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request
                  access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information;
                  and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.
                  We will consider and act upon any request in accordance with applicable data protection laws.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to
                  your local data protection supervisory authority.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Withdrawing your consent: </span>If we are relying on your consent to process your personal information, which may be express and/or
                  implied consent depending on the applicable law, you have the right to withdraw your consent at any time. However, please note that this will not affect the
                  lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance
                  on lawful processing grounds other than consent.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Opting out of marketing and promotional communications: </span>You can unsubscribe from our marketing and promotional communications
                  at any time by replying "STOP" or "UNSUBSCRIBE" to the SMS messages that we send. You will then be removed from the marketing lists. However,
                  we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account,
                  to respond to service requests, or for other non-marketing purposes.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">Account Information</span>
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  If you would at any time like to review or change the information in your account or terminate your account, you can:
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  Contact us using the contact information provided. Upon your request to terminate your account, we will deactivate or delete your account and information from our
                  active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
                  legal terms and/or comply with applicable legal requirements. If you have questions or comments about your privacy rights, you may email us at support@wokelo.ai.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Controls for do-not-track features</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal
                  your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for
                  recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically
                  communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that
                  practice in a revised version of this privacy notice.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Do California residents have specific privacy rights?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold">In Short: </span>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                  California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year
                  and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
                  addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would
                  like to make such a request, please submit your request in writing to us using the contact information provided below.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data
                  that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email
                  address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but
                  please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">Do we make updates to this notice?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  <span className="font-bold text-circuit">In Short: </span>Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </p>
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be
                  effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of
                  such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting
                  your information.
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">How can you contact us about the notice?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  If you have questions or comments about this notice, you may email us at<br/>
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">support@wokelo.ai or by post to:<br /></span>
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">Wokelo Inc</span>. <br />
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">92 Lenora Street, Seattle, Washington 98121</span>. <br />
                <span className="font-bold font-roboto text-carbon text-sm sm:text-base">United States</span>. <br />
                </p>
              </div>

              <h2 className="text-2xl lg:text-3xl font-semibold text-black mb-4 mt-8">How can you review, update, or delete the data we collect from you?</h2>
              <div className="space-y-3 pb-4">
                <p className="text-circuit font-roboto text-sm sm:text-base">
                  Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you,
                  change that information, or delete it.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {isMobile ? <MobileFooter /> : <DesktopFooter />}
    </>
  );
};

export default LegalPages;