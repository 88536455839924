import React, { useState, useEffect } from "react";
import { s } from "../Home/utils";
import Navbar from "../Navbar";
import Paragraph from "../Paragraph";
import Heading from "../Heading";
import "./Form.scss";
import useDebounce from "../../hooks/useDebounce";
import { emailsBlock } from "../../utils/blackListEmails";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Api } from "../../hooks/Api";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { industryList } from "../../utils/constants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Theme, useTheme } from "@mui/material/styles";
import CalendlyWidget from "../wokelo_calendly";
import { countryCodes } from "../../utils/countryCodes";
import "/node_modules/flag-icons/css/flag-icons.min.css";

type Props = {};

// Define Zod schema for form validation
const formSchema = z.object({
    username: z.string()
        .min(1, "Required")
        .email("Invalid email format"),
    firstname: z.string()
        .min(1, "Required"),
    lastname: z.string()
        .min(1, "Required"),
    organisation: z.string()
        .min(1, "Required"),
    industry: z.string()
        .min(1, "Required"),
    usecase: z.string(),
    countryCode: z.string(),
    phoneNo: z.string()
        .refine((value) => {
            if (!value) return true; // Allow empty phone numbers
            return /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(value);
        }, "Invalid phone number"),
    checkBox: z.boolean()
        .refine(val => val === true, {
            message: "You must accept the privacy policy",
        }),
});

// Type for our form inputs based on the schema
type FormInputs = z.infer<typeof formSchema>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CountryMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 100,
        },
    },
};

const RequestAccessForm = (props: Props) => {
    const [emailError, setEmailError] = useState<string>("");
    const [formError, setFormError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [modalCalendly, setModalCalendly] = useState(false);
    const [userDetails, setUserDetails] = useState({});

    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");
    const navigate = useNavigate();

    const sessionSource = JSON.parse(sessionStorage.getItem("source") as string);

    // Set up react-hook-form with zod resolver
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        trigger,
        reset
    } = useForm<FormInputs>({
        resolver: zodResolver(formSchema),
        mode: "onSubmit",
        defaultValues: {
            username: email || "",
            firstname: "",
            lastname: "",
            organisation: "",
            industry: "",
            usecase: "",
            countryCode: "US",
            phoneNo: "",
            checkBox: false
        }
    });

    // Watch form values for real-time validation
    const username = watch("username");
    const countryCode = watch("countryCode");
    const debouncedEmail = useDebounce(username, 500);

    // Initialize email from URL parameters
    useEffect(() => {
        if (email) {
            setValue("username", email);
        }
    }, [email, setValue]);

    // Check for blacklisted email domains
    useEffect(() => {
        const emailArr = debouncedEmail?.split("@");

        if (emailArr && emailArr?.length >= 2) {
            if (emailsBlock.includes(emailArr[1])) {
                setEmailError("Please use your corporate email address");
            } else {
                setEmailError("");
            }
        }
    }, [debouncedEmail]);

    function handleSkip(scheduled?: boolean) {
        navigate("/thank-you");
        setModalCalendly(false);
    }

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        setFormError("");
        setLoading(true);

        const selectedC = countryCodes.find((e) => e.code === data.countryCode)?.dial_code;

        const payload = {
            username: data.username,
            firstname: data.firstname,
            lastname: data.lastname,
            organisation: data.organisation,
            industry: data.industry,
            usecase: data.usecase,
            source: sessionSource ?? "",
            country_code: selectedC,
            phone_number: data.phoneNo
        };

        setUserDetails(payload);

        const emailArr = data.username?.split("@");

        if (emailArr && emailArr?.length >= 2) {
            if (emailsBlock.includes(emailArr[1])) {
                setEmailError("Please use your corporate email address");
                setLoading(false);
                return;
            } else {
                setEmailError("");
            }
        }

        setModalCalendly(true);
        await Api.requestAccess(payload)
            .then((res) => {
                if (res?.message) {
                    setLoading(false);
                } else {
                    setFormError("'Something Went Wrong, Please try again..'");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.error(err);
                setFormError("'Something Went Wrong, Please try again..'");
                setLoading(false);
            });
    };

    const sourceToTitle = {
        'pewire-newsletter-wokelo': 'Hyper-accelerated research for private markets',
    }
    const sourceToSubTitle = {
        'pewire-newsletter-wokelo': 'Schedule Demo',
    }
    const sourceToDesc = {
        'pewire-newsletter-wokelo': 'Exclusive - PE Wire only. Please fill in your details to speak with an expert.',
    }

    return (
        <section>
            <CalendlyWidget open={modalCalendly} setOpen={handleSkip} userDetails={userDetails} />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col request-access-form w-full md:w-full my-8"
            >
                <div className="mb-3">
                    <input
                        type="email"
                        className={`w-full text-sm data-hj-allow ${errors.username ? "border-red-500" : ""}`}
                        placeholder="Business email *"
                        {...register("username")}
                        data-hj-allow={true}
                    />
                    {(errors.username || emailError) && (
                        <div
                            className="text-red-500 text-xs w-full text-left mt-1"
                        ><span
                            className="text-red-500 text-xs"
                            style={{ lineHeight: "10px" }}
                        >
                                {emailError || errors.username?.message}
                            </span>
                        </div>
                    )}
                </div>

                <div className="mb-3 grid grid-cols-2 gap-3">
                    <div>
                        <input
                            className={`w-full text-sm data-hj-allow ${errors.firstname ? "border-red-500" : ""}`}
                            placeholder="First name *"
                            type="text"
                            {...register("firstname")}
                            data-hj-allow={true}
                        />
                        {errors.firstname && (
                            <div
                                className="text-red-500 text-xs w-full text-left mt-1"
                            ><span
                                className="text-red-500 text-xs"
                                style={{ lineHeight: "10px" }}
                            >
                                    {errors.firstname.message}
                                </span>
                            </div>
                        )}
                    </div>
                    <div>
                        <input
                            className={`w-full text-sm data-hj-allow ${errors.lastname ? "border-red-500" : ""}`}
                            type="text"
                            placeholder="Last name *"
                            {...register("lastname")}
                            data-hj-allow={true}
                        />
                        {errors.lastname && (
                            <div
                                className="text-red-500 text-xs w-full text-left mt-1"
                            ><span
                                className="text-red-500 text-xs"
                                style={{ lineHeight: "10px" }}
                            >
                                    {errors.lastname.message}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className="mb-3">
                    <input
                        className={`w-full text-sm data-hj-allow ${errors.organisation ? "border-red-500" : ""}`}
                        placeholder="Company name *"
                        type="text"
                        {...register("organisation")}
                        data-hj-allow={true}
                    />
                    {errors.organisation && (
                        <div
                            className="text-red-500 text-xs w-full text-left mt-1"
                        ><span
                            className="text-red-500 text-xs"
                            style={{ lineHeight: "10px" }}
                        >
                                {errors.organisation.message}
                            </span>
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <Select
                        displayEmpty
                        className={`w-full p-0 rounded-xl placeholder:text-gray-500 text-sm ${errors.industry ? "border-red-500" : ""}`}
                        value={watch("industry")}
                        onChange={(e) => setValue("industry", e.target.value, { shouldValidate: true })}
                        input={<OutlinedInput />}
                        sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                                border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                border: 0,
                            },
                        }}
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span className="text-[#717188] text-sm">Industry *</span>
                                );
                            }

                            return (
                                <span className="text-[#717188] text-sm">{selected}</span>
                            );
                        }}
                        MenuProps={MenuProps}
                        inputProps={{
                            "aria-label": "Without label",
                            "data-hj-allow": true,
                        }}
                    >
                        {industryList.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.industry && (
                        <div
                            className="text-red-500 text-xs w-full text-left mt-1"
                        ><span

                            style={{ lineHeight: "10px" }}
                        >
                                {errors.industry.message}
                            </span>
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <div className="flex gap-x-3">
                        <div>
                            <Select
                                displayEmpty
                                className="w-full p-0 rounded-xl placeholder:text-gray-500 text-sm custom-class-country"
                                value={countryCode}
                                onChange={(e) => setValue("countryCode", e.target.value)}
                                input={<OutlinedInput />}
                                sx={{
                                    width: "126px !important",
                                    height: "46px !important", // Set the height to 46px
                                    padding: "0 !important",
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                    },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                    },
                                    "& .MuiSelect-select": { // Target the select element itself
                                        padding: "0 14px !important",
                                        height: "46px",
                                        display: "flex",
                                        alignItems: "center",
                                    }
                                }}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return (
                                            <span className="text-[#717188] text-sm">
                                                Country
                                            </span>
                                        );
                                    }
                                    const selectedC = countryCodes.find((e) => e.code === selected)
                                    return (
                                        <>
                                            {selectedC && <span className={`fi fi-${selectedC?.code?.toLowerCase()} mr-3`}></span>}
                                            <span className="text-[#717188] text-sm">{selectedC?.dial_code}</span>
                                        </>
                                    );
                                }}
                                MenuProps={{ ...CountryMenuProps, className: 'country-code-menu' }}
                                inputProps={{
                                    "aria-label": "Without label",
                                    "data-hj-allow": true,
                                }}
                            >
                                {countryCodes.map((country) => (
                                    <MenuItem key={country.code} value={country.code}>
                                        <span className={`fi fi-${country.code.toLowerCase()} mr-3`}></span>
                                        {country.dial_code}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <input
                            className="w-full h-[46px] text-sm px-3 rounded-xl border border-gray-200 focus:outline-none"
                            placeholder="Phone number"
                            {...register("phoneNo")}
                            data-hj-allow={true}
                        />
                    </div>
                    {errors.phoneNo && (
                        <div
                            className="text-red-500 text-xs w-full text-left mt-1"
                        >
                            <span
                                style={{ lineHeight: "10px" }}
                            >{errors.phoneNo.message}</span>
                        </div>
                    )}
                </div>

                <div className="mb-5">
                    <input
                        className="w-full text-sm data-hj-allow"
                        placeholder="Describe use case"
                        type="text"
                        {...register("usecase")}
                        data-hj-allow={true}
                    />
                </div>

                <div className=" mb-[30px]">
                    <div className="flex gap-3 items-center">
                        <input
                            type="checkbox"
                            className="h-4 w-4 rounded-md focus:ring-0 cursor-pointer data-hj-allow"
                            style={{ border: "1.5px solid #E3E4EB", borderRadius: 5 }}
                            {...register("checkBox")}
                            data-hj-allow={true}
                        />
                        <p className="text-[13px] text-iron font-normal">
                            <span>
                                I have read and agree to the Wokelo&nbsp;
                                <a
                                    href={"/legals?tab=privacy"}
                                    target="_blank"
                                    className="text-theme underline cursor-pointer"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </span>
                        </p>
                    </div>
                    {errors.checkBox && (
                        <div
                            className="text-red-500 text-xs w-full text-left mt-1"
                        ><span
                            style={{ lineHeight: "10px" }}
                        >
                                {errors.checkBox.message}
                            </span>
                        </div>
                    )}
                </div>

                <div className="flex justify-center">
                    <button
                        className="flex flex-col w-full justify-center items-center rounded py-3 uppercase bg-nano-light font-roboto-mono disabled:cursor-not-allowed disabled:shadow-none disabled:bg-circuit/60"
                        type="submit"
                        disabled={loading || !!emailError || Object.values(errors).length > 0}
                    >
                        {loading ? (
                            <CircularProgress
                                sx={{
                                    color: "#343434",
                                    fontSize: 14,
                                    width: "16px !important",
                                    height: "16px !important",
                                }}
                            />
                        ) : (
                            <p className={"text-sm font-medium"}>Schedule a call</p>
                        )}
                    </button>
                </div>

                <div>
                    {formError && (
                        <div
                            className="text-red-500 text-xs w-full text-left mt-1"
                        ><span
                            className="text-red-500 text-xs"
                            style={{ lineHeight: "10px" }}
                        >
                                {formError}
                            </span>
                        </div>
                    )}
                </div>
            </form>
        </section>
    );
};

export default RequestAccessForm;