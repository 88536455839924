import Navbar from '../Navbar'
import FooterNew from '../../core/components/Footer/Footer'
import Challenges from '../../core/components/Solution/Challenges'
import HowItWorks from '../../core/components/Home/HowItWorksV2'
import WhyWokeloSolutions from '../../core/components/Solution/WhyWokeloSolutions'
import UseCases from '../../core/components/Solution/UseCases'
import Discover from '../../core/components/Solution/Discover'
import MetaTags from '../../core/components/Meta/Meta'
import { privateEquityMeta } from '../../core/utils/meta'


const Content = {
    challenges: {
        title: <>The challenge of traditional private equity research</>,
        subtitle: <>Sourcing gaps, poor company coverage, and lengthy<br />
            due diligence drain time and budgets.</>,
        mobilesubtitle: <>Sourcing gaps, poor company coverage, and lengthy
            due diligence drain time and budgets.</>,
        image: 'Management.png',
        points: [
            'Time lost to manual data gathering instead of strategic decision-making',
            'Blind spots in your pipeline and portfolio, due to obscure or limited private market data',
            'Burnt-out research teams and rising data subscription costs',
        ]
    },

    why: {
        title: <>Wokelo automates and accelerates private equity research, ensuring you focus on high-impact decisions, stakeholder engagement, and business expansion</>,
        points: [
            'Win more deals with faster, high-quality sourcing and accelerate deal cycles with rigorous AI-powered due diligence',
            'Make better investment decisions and generate alpha with unique insights and proactive monitoring of markets and portfolios',
            'Scale seamlessly—expand deal coverage, sector insights, and geographic reach without increasing headcount or workload',
        ],
        logos: ['/ticker/berkshire-solutions.svg', '/ticker/insight-solutions.svg', '/ticker/harbright-solutions.svg', '/ticker/premji-solutions.svg', '/ticker/cowles-solutions.svg']
    },
    usecases: {
        title: 'Top Wokelo use cases for Private Equity',
        usecases: [
            {
                title: 'Enhanced coverage & deal flow quality',
                left: {
                    badge: 'Market Landscape',
                    points: [
                        'Key trends & innovation',
                        'Value chain',
                        'Precedent M&A transactions'
                    ]
                },
                right: {
                    badge: 'Deal sourcing market maps',
                    points: [
                        'Sub-segments & categories',
                        'Curated, detailed company lists',
                        'Deal screening',
                    ]
                }
            },
            {
                title: 'Faster Lead Qualification',
                left: {
                    badge: 'Fact Pack & Deep Dives',
                    points: [
                        'Company research',
                        'Market dynamics',
                        'Competitive benchmarking'
                    ]
                },
                right: {
                    badge: 'Investment Thesis',
                    points: [
                        'Data room analysis',
                        'CIM analysis',
                        'Outside-in market data',
                    ]
                }
            },
            {
                title: 'Proactive, detailed portfolio tracking',
                left: {
                    badge: 'Post-investment monitoring',
                    points: [
                        'Portfolio performance',
                        'Market & competitor moves',
                        'Company news & insights'
                    ]
                },
                right: {
                    badge: 'Periodic & real-time alerts',
                    points: [
                        'Configurable news digests',
                        'Detailed portfolio digests',
                        'Trending themes & topics',
                    ]
                }
            },
            {
                title: 'Timely insight into market shifts',
                left: {
                    badge: 'Ad-hoc research tasks',
                    points: [
                        'Corporate structure changes',
                        'Regulatory issues & updates',
                        'Technology distruption'
                    ]
                },
                right: {
                    badge: 'Recurring reporting',
                    points: [
                        'Daily industry briefing',
                        'Quarterly financial summary',
                        'Weekly M&A newsletter',
                    ]
                }
            }
        ]
    }
}

function SolutionsPagePE() {
    return (
        <div className='relative !bg-white overflow-x-hidden' >
            <MetaTags metaProps={privateEquityMeta} />
            <div className='relative' >
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"

                />

                <Navbar className='!bg-none' />
                <div className='w-3/4 mx-auto text-center min-h-[280px] md:min-h-[500px] flex justify-center items-center'>
                    <h1 className='text-carbon text-[40px] lg:text-[80px] font-semibold font-aeonik leading-[40px] lg:leading-[72px]'>
                        Wokelo for Private Equity
                    </h1>
                </div>

            </div>

            <Challenges {...Content.challenges} />
            <WhyWokeloSolutions {...Content.why} />
            <Discover />
            <UseCases {...Content.usecases} />
            <HowItWorks />
            <FooterNew />
        </div>
    )
}

export default SolutionsPagePE