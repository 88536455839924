import { useEffect } from "react";

const RedirectToVC = () => {
  useEffect(() => {
    window.location.href="https://signup.wokelo.ai/wokelo-for-investors/venture5/";
  }, []);

  return null;
};

export default RedirectToVC;
