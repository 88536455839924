import { useState } from "react";
import { DashLineIcon } from "../../icons/icons";
import { useMediaQuery } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";


const FAQItem = ({ id, question, answer, isOpen, toggleOpen }) => {
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <div className={`p-3 md:p-5 border-b pt-8 rounded-[10px] border-[#97C8A6] ${isOpen ? "bg-[#FFFFFF]" : "bg-[#EFEFEF] "}`}>
      <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleOpen(id)}>
        <div className={`flex items-center ${isOpen ? isMobile ? 'w-[85%]' : "" : "w-[85%]"}`}>
          <span className={` ${isOpen ? "text-gradient-dark" : "text-iron"} font-roboto-mono text-md pl-10 pr-16 font-medium w-10 hidden md:block`}>{id}</span>
          <span
            className={`text-md md:text-lg font-roboto pl-0 md:pl-5 flex-1 ${isOpen ? `font-medium  bg-gradient-to-r from-gradient-dark to-gradient-light text-transparent bg-clip-text` : "text-shade"}`}
          >
            {question}
          </span>

        </div>
        { isMobile ? isOpen ?
          <button className="pr-5 text-2xl font-light w-8 h-8 items-center justify-center">
           <DashLineIcon color={"#A1CCA5"} width={'16.3px'} /> 
         </button>: 
         <button className="pr-5 text-2xl font-light w-8 h-8 items-center justify-center">
         <ChevronLeft className="-rotate-90 stroke-[#A1CCA5] scale-110" />
       </button>
         :
          <button className="pr-10 text-2xl font-light w-8 h-8 items-center justify-center">
          {isOpen ? <DashLineIcon color={"#A1CCA5"} /> : <ChevronLeft className="-rotate-90 stroke-[#A1CCA5] scale-110" />}
          </button>
        }

      </div>

      {isOpen && (
        <div className="mt-3 md:ml-[124px]  text-metal font-roboto text-[13px] md:text-base  leading-relaxed">
          {answer.map((item, index) => (
            <div key={index} className="mb-1 w-full md:w-[65%]">{item}</div>
          ))}
        </div>
      )}

    </div>
  );
};




const FAQSection = () => {
  const [openItem, setOpenItem] = useState(null);

  const faqData = [
    {
      id: "01",
      question:
        "How accurate are the results, how does Wokelo eliminate hallucinations?",
      answer:
        ["Wokelo is a privacy centered secure platform. Some of the most highly regulated financial services players and banks trust Wokelo with their confidential and proprietary data. We are SOC 2 compliant, which ensures your data is secure and encrypted at all times.",
          "Internal company data is not used for model training. Any uploaded internal data on Wokelo is automatically deleted after 30-days. Data deletion can also be initiated on-demand by customer. We support both cloud hosted and on-premise deployments to support varying needs of organizations. "
        ]
    },
    {
      id: "02",
      question: "How will Wokelo keep my internal company data private and secure?",
      answer:
        ["Wokelo employs enterprise-grade security measures to keep your data private. All data is encrypted both in transit and at rest. We maintain strict access controls and do not use customer data for training our models.",
        ]
    },
    {
      id: "03",
      question:
        "Which data sources come pre-integrated with Wokelo, do I have to pay more?",
      answer:
        ["Wokelo comes with many pre-integrated data sources included in the base subscription. Premium data sources may require additional licensing depending on your plan.",
        ]
    },
    {
      id: "04",
      question: "How does Wokelo save me time and money?",
      answer:
        ["By automating research and data analysis processes, Wokelo reduces the time spent on manual research by up to 80%. This translates to significant cost savings and allows your team to focus on higher-value activities.",
        ]
    },
    {
      id: "05",
      question: "What type of customizations are supported by Wokelo?",
      answer:
        ["Wokelo supports various customizations including custom data connectors, workflow integrations, report templates, and model fine-tuning to meet specific industry requirements.",
        ]
    },
  ];

  const toggleOpen = (id) => {
    setOpenItem(openItem === id ? null : id);
  };

  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <div className="bg-[#EFEFEF] p-5 md:py-14">
      <div className="max-full mx-auto container">
        <h1 className="text-3xl md:text-6xl mt-2 font-aeonik text-dark-text font-bold mb-5 text-left">
          Frequently asked questions
        </h1>
        <p className="text-circuit mb-8 text-left font-roboto">
          Add your sources instantly. Our models make a wide array of
          unstructured {isMobile ? <></> : <br />}  qualitative data usable, which was not possible before.
        </p>

        <div className="rounded-lg">
          {faqData.map((item) => (
            <FAQItem
              key={item.id}
              id={item.id}
              question={item.question}
              answer={item.answer}
              isOpen={openItem === item.id}
              toggleOpen={toggleOpen}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;