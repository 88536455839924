import React from 'react'
import Paragraph from './Paragraph'

function TrustedByContact({ logos }: { logos: string[] }) {
    return (
        <div
            className={`flex justify-center `}
        >
            <div className="w-full flex flex-col gap-2 lg:flex-row items-start md:items-center justify-between">
                <h2 className="text-md md:text-lg font-medium lg:items-center justify-center text-carbon md:p-2 md:pr-5 lg:ml-8 lg:border-r  lg:border-carbon">
                    Trusted by <span className="inline md:block md:whitespace-nowrap">industry leaders</span>
                </h2>
                <div
                    className={`w-fit flex flex-row justify-start items-center ipadGrid gap-8 xl:gap-9 2xl:gap-16 overflow-hidden flex-wrap mt-2`}
                >
                    {
                        logos.map((logo) => {
                            return <div className={""} key={logo}>
                                <picture>
                                    <source
                                        srcSet={logo}
                                        type="image/webp"
                                    />
                                    <img
                                        src={logo}
                                        alt="776"
                                        className="h-[25px] z-30 object-scale-down md:h-auto my-auto max-w-[87px] xl:max-w-none "

                                    />
                                </picture>
                            </div>
                        })
                    }

                </div>

            </div>
        </div>
    )
}

export default TrustedByContact