import Navbar from '../Navbar'
import FooterNew from '../../core/components/Footer/Footer'
import Challenges from '../../core/components/Solution/Challenges'
import HowItWorks from '../../core/components/Home/HowItWorksV2'
import WhyWokeloSolutions from '../../core/components/Solution/WhyWokeloSolutions'
import UseCases from '../../core/components/Solution/UseCases'
import Discover from '../../core/components/Solution/Discover'
import MetaTags from '../../core/components/Meta/Meta'
import { ventureCapitalMeta } from '../../core/utils/meta'


const Content = {
    challenges: {
        title: <>The challenge of traditional venture capital research</>,
        subtitle: <>Limited coverage on startups and emerging industries,<br />
            sourcing gaps, and resource-heavy due diligence make it<br />
            harder to stay ahead.</>,
        mobilesubtitle: <>Limited coverage on startups and emerging industries,
        sourcing gaps, and resource-heavy due diligence make it
        harder to stay ahead.</>,
        image: 'venture.png',
        points: [
            'Missed opportunities and slower deal flow',
            'Burnt-out research teams chasing fragmented startup data',
            'Less time for high-value engagement with founders',
        ]
    },

    why: {
        title: <>Wokelo automates and accelerates venture capital research, ensuring you stay ahead of market trends - without the
            blind spots</>,
        points: [
            'Win more deals with faster, high-quality startup sourcing and exclusive, differentiated insights',
            'Make smarter, high-impact investments with real-time market intelligence, rapid due diligence, and in-depth competitor analysis',
            'Expand market coverage and diversify investments across new sectors and geographies—without increasing headcount or workload',
        ],
        logos: ['/ticker/bcapital-solutions.svg', '/ticker/supercruise-solutions.svg', '/ticker/draper-solutions.svg', '/ticker/776-solutions.svg', '/ticker/breakwater-solutions.svg']
    },
    usecases: {
        title: 'Top Wokelo use cases for Venture Capital',
        usecases: [
            {
                title: 'Expanded coverage quality of deal flow',
                left: {
                    badge: 'Industry & market landscape',
                    points: [
                        'Trends & innovations',
                        'Value chain',
                        'Opportunities & risks'
                    ]
                },
                right: {
                    badge: 'Deal sourcing market maps',
                    points: [
                        'Market segments',
                        'Curated company lists',
                        'Fundraising analysis',
                    ]
                }
            },
            {
                title: 'Accelerated, high-rigor due diligence ',
                left: {
                    badge: 'Day-0 Fact pack',
                    points: [
                        'Company research',
                        'Management strength',
                        'Competitive benchmarking'
                    ]
                },
                right: {
                    badge: 'Deep dive analysis',
                    points: [
                        'Supplier & customer analysis',
                        'Employee & customer sentiment',
                        'Financial & operational insights',
                    ]
                }
            },
            {
                title: 'Proactive, granular portfolio monitoring',
                left: {
                    badge: 'Post-Investment Monitoring',
                    points: [
                        'Portfolio performance',
                        'Market & competitive moves',
                        'Company news & insights'
                    ]
                },
                right: {
                    badge: 'Periodic & Real Time Alerts',
                    points: [
                        'Custom news digests',
                        'Hyper-personalized updates',
                        'Industry / topic-level synthesis',
                    ]
                }
            },
            {
                title: 'Timely insight into market shifts',
                left: {
                    badge: 'Ad-hoc research tasks',
                    points: [
                        'Trend identification',
                        'Decision support',
                        'Market dynamics'
                    ]
                },
                right: {
                    badge: 'Recurring reporting',
                    points: [
                        'Daily briefings',
                        'Quarterly financial summaries',
                        'Weekly dealmaking trends',
                    ]
                }
            }
        ]
    }
}

function SolutionsPageVC() {


    return (
        <div className='relative !bg-white overflow-x-hidden'>
            <MetaTags metaProps={ventureCapitalMeta} />

            <div className='relative'>
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"
                />
                <Navbar className='!bg-none' />
                <div className='w-3/4 mx-auto text-center min-h-[280px] md:min-h-[500px] flex justify-center items-center'>
                    <h1 className='text-carbon text-[40px] lg:text-[80px] font-semibold font-aeonik leading-[40px] lg:leading-[72px]'>
                        Wokelo for Venture Capital
                    </h1>
                </div>
            </div>
            <Challenges {...Content.challenges} />
            <WhyWokeloSolutions {...Content.why} />
            <Discover />
            <UseCases {...Content.usecases} />
            <HowItWorks />
            <FooterNew />
        </div>
    )
}

export default SolutionsPageVC