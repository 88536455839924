import React from "react";

const DesktopFooter = () => {
    return (
        <footer className="w-full bg-shade text-mint pt-12 px-6 mt-auto">
            <div className="container mx-auto ">
                <div className="flex flex-col md:flex-row justify-between items-start ">
                    <div className="mb-6 md:mb-0 md:w-[50%]">
                        <h2 className="text-[35px] xl:text-[41px] font-semibold pt-3 text-ash max-w-xl !leading-[100%] font-aeonik">Say Hello to the Future of Work</h2>
                        <p className="text-md mt-4 font-roboto tracking-wider">Get ready to supercharge your research</p>
                    </div>

                    <div className=" md:w-[50%]">
                        <div className="flex justify-between">
                            <div className="flex md:gap-11 text-xs font-mono uppercase">
                                <div className="space-y-4 mr-6">
                                    <span className="text-ash/30">PRODUCTS</span>
                                    <ul className="space-y-2.5">
                                        <li><a href="/#howitworks">HOW IT WORKS</a></li>
                                        <li><a href="/#solutions">SOLUTIONS</a></li>
                                        <li><a href="https://insights.wokelo.ai/" target="_blank">Insights</a></li>
                                    </ul>
                                </div>

                                <div className="space-y-4 mr-6">
                                    <span className="text-ash/30">Solutions</span>
                                    <ul className="space-y-2.5">
                                        <li><a href="/for-private-equity">Private Equity</a></li>
                                        <li><a href="/for-investment-banking">investment banking</a></li>
                                        <li><a href="/for-corporate-strategy">Corporate strategy</a></li>
                                        <li><a href="/for-venture-capital">venture capital</a></li>
                                        <li><a href="/for-management-consulting">management consulting</a></li>
                                        <li><a href="/for-fund-of-funds">Fund-of-Funds</a></li>
                                    </ul>
                                </div>

                                <div className="space-y-4 mr-6">
                                    <span className="text-ash/30">Company</span>
                                    <ul className="space-y-2.5">
                                        <li><a href="/about-us">About us</a></li>
                                        <li><a href="/legals">legal</a></li>
                                        <li><a href="https://jobs.ashbyhq.com/wokelo-ai">Careers</a></li>
                                    </ul>
                                </div>

                            </div>
                            <div className="space-y-4 text-xs font-mono uppercase">
                                <span className="text-ash/30">Social</span>

                                <ul className="space-y-2.5">
                                    <li><a href="https://www.linkedin.com/company/wokelo-ai" target="_blank">Linkedin</a></li>
                                    <li><a href="https://x.com/WokeloAi" target="_blank">X</a></li>
                                </ul>
                            </div>

                        </div>

                        <div className="border-t border-mint mt-10 pt-4 text-xs flex flex-col md:flex-row justify-between">
                            <p className="pt-2">WOKELO INC. SEATTLE, WA, USA</p>
                            <p className="pt-2">
                                <a target="_blank" href="mailto:support@wokelo.ai">
                                    SUPPORT@WOKELO.AI
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-col justify-between items-center mt-24">
                    <img
                        src="/Logo/Logo-lg.svg"
                        alt="Logo"
                        className="w-full h-auto"
                    />
                    <p className="text-gray-500 text-xs mt-4 md:mt-4 font-mono">&copy; 2025 Wokelo AI | Wokelo Inc. All rights reserved</p>
                </div>
            </div>
        </footer>
    );
};

export default DesktopFooter;
