import React, { useState } from 'react'
import Input from '../InputV2';
import { useMediaQuery } from 'usehooks-ts';
import { useTheme } from '@mui/material';

function Discover() {
    const [email, setEmail] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div className='bg-carbon relative px-5 md:px-14 py-10 lg:py-20'>
            <div className='grid grid-cols-5'>
                <div className='col-span-5 lg:col-span-3'>
                    <h3 className='text-mint font-aeonik text-[28px] lg:text-[40px] leading-10 font-medium'>
                        Discover how Wokelo drives rigor and efficiency in your research
                    </h3>
                </div>
                <div className='col-span-5 lg:col-span-2 w-full flex justify-end mt-8 lg:mt-0'>
                    <Input
                        // ref={ref}
                        className="relative flex items-center  bg-[#272B2E] justify-between max-w-md p-3 border border-[#757E8780] rounded-md h-fit w-[100%] lg:w-[90%] md:w-[450px]"
                        inputClassName=" px-2 md:px-4 py-1 focus:outline-none text-sm md:text-md focus:ring-0  rounded-md bg-[#272B2E] text-white"
                        buttonClass='absolute  right-1.5 top-1.5 bottom-1.5 px-2 md:px-4 text-[10px] md:text-sm bg-nano-light text-carbon font-medium rounded-md flex items-center justify-center'
                        placeholder="Your e-mail address"
                        variant="nano"
                        value={email}
                        isMobile={isMobile}
                        buttonText={["BOOK A DEMO", "BOOK A DEMO"]}
                        onInput={(e) => {
                            setEmail(e.currentTarget.value);
                        }}
                    />

                </div>
            </div>
        </div>
    )
}

export default Discover