import Navbar from '../Navbar'
import FooterNew from '../../core/components/Footer/Footer'
import Challenges from '../../core/components/Solution/Challenges'
import HowItWorks from '../../core/components/Home/HowItWorksV2'
import WhyWokeloSolutions from '../../core/components/Solution/WhyWokeloSolutions'
import UseCases from '../../core/components/Solution/UseCases'
import Discover from '../../core/components/Solution/Discover'
import { fundOfFundsMeta } from '../../core/utils/meta'
import MetaTags from '../../core/components/Meta/Meta'


const Content = {
    challenges: {
        title: <>The challenge of traditional research in fund-of-funds</>,
        subtitle: <>Fund-of-funds struggle with capacity constraints, limited visibility<br />
            into fund strategies, and the complexity of multi asset<br />
            investments—leading to gaps in decision-making.</>,
        mobilesubtitle: <>Fund-of-funds struggle with capacity constraints, limited visibility
        into fund strategies, and the complexity of multi asset
        investments—leading to gaps in decision-making.</>,
        image: 'Management.png',
        points: [
            'Missed opportunities due to fragmented insights and slow analysis',
            'Limited transparency into fund portfolio holdings, creating blind spots in evaluations',
            'Excessive time spent on data gathering instead of strategic decision-making',
        ]
    },

    why: {
        title: <>Wokelo streamlines fund analysis, accelerates portfolio insights,
            and enhances research transparency —enabling smarter, faster investment decisions.</>,
        points: [
            'Quickly analyze funds using a comprehensive set of strategic and operational attributes for smarter investment decisions',
            'Gain granular insights at the portfolio company level to better evaluate fund strategy, direction, and risk exposure',
            'Scale productivity and redirect time and resources to higher-order activities and strategic decision-making',
        ],
        logos: ['/ticker/insight-solutions.svg', '/ticker/seven_2-solutions.svg', '/ticker/berkshire-solutions.svg', '/ticker/guggenheim-sol.svg', '/ticker/premji-solutions.svg', '/ticker/harbright-solutions.svg']
    },
    usecases: {
        title: 'Top Wokelo Use Cases for Fund-of-funds',
        usecases: [
            {
                title: 'Timely insight into market shifts',
                left: {
                    badge: 'Ad-hoc Research Tasks',
                    points: [
                        'Trend identification',
                        'Decision support',
                        'Market dynamics '
                    ]
                },
                right: {
                    badge: 'Recurring reporting',
                    points: [
                        'Daily briefings',
                        'Quarterly financial summaries',
                        'Weekly dealmaking trends',
                    ]
                }
            },
            {
                title: 'Accelerated, high-rigor due diligence ',
                left: {
                    badge: 'Fact pack & Deep Dives',
                    points: [
                        'Company research',
                        'Market dynamics',
                        'Competitive benchmarking'
                    ]
                },
                right: {
                    badge: 'Investment Thesis & Memo',
                    points: [
                        'Data room analysis',
                        'CIM analysis',
                        'Outside-in market data',
                    ]
                }
            },
            {
                title: 'Rapid evaluation of fund opportunities',
                left: {
                    badge: 'Comprehensive assessment',
                    points: [
                        'Fund overview & strategy',
                        'Fund performance & benchmarks',
                        'Investment & fundraising'
                    ]
                },
                right: {
                    badge: 'Contextual layering',
                    points: [
                        'Fund files & call transcripts',
                        'Market & industry reports',
                        'News & public data',
                    ]
                }
            },
            {
                title: 'Proactive, granular portfolio tracking',
                left: {
                    badge: 'Post-investment monitoring',
                    points: [
                        'Portfolio performance',
                        'Market & competitor moves',
                        'Company news & insights'
                    ]
                },
                right: {
                    badge: 'Periodic & real-time alerts',
                    points: [
                        'Configurable news digests',
                        'Detailed portfolio digests',
                        'Trending themes & topics',
                    ]
                }
            }
        ]
    }
}

function SolutionsPageFF() {

    return (
        <div className='relative !bg-white overflow-x-hidden' >
            <MetaTags metaProps={fundOfFundsMeta} />

            <div className='relative'>
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"

                />

                <Navbar className='!bg-none' />
                <div className='w-3/4 mx-auto text-center min-h-[280px] md:min-h-[500px] flex justify-center items-center'>
                    <h1 className='text-carbon text-[40px] lg:text-[80px] font-semibold font-aeonik leading-[40px] lg:leading-[72px]'>
                        Wokelo for Fund-of-funds
                    </h1>
                </div>
            </div>
            <Challenges {...Content.challenges} />
            <WhyWokeloSolutions {...Content.why} />
            <Discover />
            <UseCases {...Content.usecases} />
            <HowItWorks />
            <FooterNew />
        </div>
    )
}

export default SolutionsPageFF