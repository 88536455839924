import Navbar from "./Navbar";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import { useMediaQuery } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import EmailInput from "../core/components/HomePage/EmailInput";
import TrustedByLandingPage from "../core/components/HomePage/LandingVideo";
import TrustedBy from "../core/components/HomePage/TrustedBy";
import FeatureSection from "../core/components/Home/FeatureSectionV2";
import HowItWorks from "../core/components/Home/HowItWorksV2";
import Solutions from "../core/components/Home/SolutionsV2";
import Soc2 from "../core/components/Home/Soc2V2";
import WhyCustomerLovesWokeloCarousel from "../core/components/Home/WhyCustomerLoveWokeloCarouselV2";
import HumanProductivity from "../core/components/HomePage/HumanProductivity";
import FAQSection from "../core/components/HomePage/FAQSection"
import MetaTags from "../core/components/Meta/Meta";
import { homePageMeta } from "../core/utils/meta";

const HomePage = () => {
  const [scrollHeight, setScrolHeight] = useState(1);
  const [scrollY, setScrollY] = useState(0);
  const [landing, setLanding] = useState(false);
  const inputRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:770px)');

  function logit() {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  const isElementXPercentInViewport = function () {
    const viewportHeight = window.innerHeight;
    const scrollPercentage = (window.scrollY / viewportHeight) * 100;

    if (scrollPercentage >= 55) {
      return 4;
    } else if (scrollPercentage >= 40) {
      return 3;
    } else if (scrollPercentage >= 20) {
      return 2;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    setScrolHeight(isElementXPercentInViewport());

    if (
      scrollY > 20 &&
      (document.activeElement !== inputRef.current || !isMobile)
    ) {
      if (!landing) {
        setLanding(true);
      }
    } else if (scrollY == 0) {
      setLanding(false);
    } else {
      return;
    }
  }, [scrollY]);

  return (
    <>
      <MetaTags metaProps={homePageMeta} />
      <Navbar className="bg-white-bg" />
      <div className="flex flex-col w-full h-full bg-white-bg relative ">
        <h1 className="px-2 flex font-aeonik text-carbon font-semibold text-center mx-auto my-2 mt-8 md:mt-16 text-4xl md:text-[5rem] md:max-w-[1000px]  md:!leading-[90%]"
          style={{ letterSpacing: '-2%' }}
        >
          Hyper-accelerated research and due diligence
          powered by Gen-AI
        </h1>
        <div className={`px-2 mt-[1.9rem] md:mt-10 w-full max-w-[85%] md:max-w-[627px] mx-auto`}>
          <p className="text-circuit leading-[24px] text-sm md:text-base text-center font-roboto">
            Comprehensive and credible insights for investment and strategy
            professionals. Enterprise-grade and privacy-centered secure platform.
          </p>
        </div>
        <div className="flex justify-center sm:justify-start mt-[30px] py-2 px-5 md:mb-20 ">
          <EmailInput isMobile={isMobile} ref={inputRef} />
        </div>
        <TrustedByLandingPage scrollHeight={scrollHeight} />
        <div>
          <TrustedBy />
        </div>
        <div className="bg-shade">
          <FeatureSection isMobile={isMobile} />
          <HowItWorks />
        </div>
        <Solutions />
        <Soc2 />
        <WhyCustomerLovesWokeloCarousel />
        <HumanProductivity />
        <FAQSection />
      </div>
      {isMobile ? <MobileFooter /> : <DesktopFooter />}
    </>
  )
}

export default HomePage;