import { InputHTMLAttributes, forwardRef, useRef, useState } from "react";
import Button, { ButtonVariant } from "./ButtonV2";
import ArrowIcon from "../icons/Arrow";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Api } from "../hooks/Api";
import CalendlyWidget from "./wokelo_calendly";
import TopRightArrowIcon from "../icons/TopRightArrow";

export type InputProps = {
  disableOutline?: boolean;
  inputClassName?: string;
  buttonClass?: string;
  value?: string;
  variant?: ButtonVariant;
  buttonText?: string[];
  isMobile?: boolean;
  ref?: any;
  btnLabel?: string;
  showCalendly?: boolean;
  routeAfterSubmit?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef(
  (
    {
      className,
      value,
      disableOutline = false,
      buttonClass,
      inputClassName,
      buttonText = ["SUBSCRIBE", "BOOK A DEMO"],
      isMobile,
      variant,
      btnLabel,
      showCalendly = false,
      routeAfterSubmit = true,
      ...props
    }: InputProps,
    ref?: any
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const matches = useMediaQuery("(min-width:1200px)");
    const sessionSource = JSON.parse(
      sessionStorage.getItem("source") as string
    );

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [modalCalendly, setModalCalendly] = useState(false);
    const [userDetails, setUserDetails] = useState({})

    function handleSkip(scheduled?: boolean) {
      // if (scheduled) {
      navigate("/thank-you");
      // }
      setModalCalendly(false);
    }
    const navigate = useNavigate();

    const onSubmit = async () => {
      setLoading(true);
      const payload = {
        username: value,
        source: sessionSource ?? "",
      };
      setUserDetails(payload)
      if (showCalendly) {
        setModalCalendly(true);
      }

      Api.requestAccess(payload)
        .then((res) => {
          console.log(res?.message);
          if (res?.message) {
            setSuccess(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });

      if (!showCalendly) {
        if (routeAfterSubmit) {
          navigate(`/contact-us?email=${value}`);
        } else {
          navigate("/thank-you");
        }
      }
    };

    return (
      <>
        <CalendlyWidget open={modalCalendly} setOpen={handleSkip} userDetails={userDetails} />
        <div
          className={`${className} `}
        >
          <input
            ref={ref}
            className={` ${inputClassName}  `}
            value={value}
            {...props}
          ></input>
          {/* <Link
          to="https://app.wokelo.ai/request-access"
          target="_blank"
          rel="noopener noreferrer"
        > */}
          <Button
            type="button"
            variant={variant}
            className={`${buttonClass} bg-nano-light`}
            onClick={onSubmit}
            disabled={!value?.trim()}
          >
            <div className="w-full flex justify-between items-center gap-1 mt-[1px]">
              <span>
                {isMobile ? buttonText[0] : buttonText[1]}
              </span>
              {isMobile ? (
                <TopRightArrowIcon height={18} width={18} className="ml-1" />
              ) : (
                <ArrowIcon height={13} width={13} className="ml-2 -rotate-45" />
              )}
            </div>
          </Button>
          {/* </Link> */}
        </div>
      </>
    );
  }
);

export default Input;
