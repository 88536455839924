import React, { useRef, useState, useEffect } from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import useMediaQuery from "@mui/material/useMediaQuery";
import { s } from "./utils";
import Input from "../InputV2";
import { motion, useInView, useAnimation, AnimatePresence } from "framer-motion";
import "react-multi-carousel/lib/styles.css";
import "./solutions/solutions.css";
import { DownArrowIcon, UpArrowIcon } from "../../icons/icons";
import { DashedLineIcon } from "../../icons/icons";
import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1100 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1100, min: 750 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 2,
  },
};

// Style component abstractions
type SolutionProps = {
  id: number;
  title: string;
  attributes: string[];
  img: string;
  open: boolean;
  link: string;
};

const solutions: SolutionProps[] = [
  {
    id: 1,
    title: "Private Equity",
    attributes: [
      "Due diligence",
      "Data room synthesis",
      "Valuation analysis",
      "Portfolio monitoring",
    ],
    img: "Solutions/PrivateEquity.svg",
    open: true,
    link: '/for-private-equity',
  },
  {
    id: 2,
    title: "Investment Banking",
    attributes: [
      "Investment theses",
      "Equity research",
      "M&A activity",
      "Hedge fund research",
    ],
    img: "Solutions/Investment.svg",
    open: false,
    link: '/for-investment-banking',
  },
  {
    id: 3,
    title: "Corporate Strategy",
    attributes: [
      "Corporate development",
      "Competitive intel",
      "New product strategy",
      "Signals and trends",
    ],
    img: "Solutions/CorporateStrat.svg",
    open: false,
    link: '/for-corporate-strategy',
  },
  {
    id: 4,
    title: "Venture Capital",
    attributes: [
      "Company diligence",
      "Investment memos",
      "Sector research",
      "Investment theses",
    ],
    img: "Solutions/VentureCapital.svg",
    open: false,
    link: '/for-venture-capital',
  },
  {
    id: 5,
    title: "Management Consulting",
    attributes: [
      "Market research",
      "Company research",
      "M&A activity",
      "Benchmarking",
    ],
    img: "Solutions/Management.svg",
    open: false,
    link: '/for-management-consulting',
  },
  {
    id: 6,
    title: "Fund-of-funds",
    attributes: [
      "Fund intelligence",
      "Due diligence",
      "Portfolio drill-downs",
      "Industry trends",
    ],
    img: "Solutions/funds.svg",
    open: false,
    link: '/for-fund-of-funds',
  },
];

type Props = {};

interface FeatureProps {
  name: string;
  index: number;
  isMobile: boolean;
}

const Feature: React.FC<FeatureProps> = ({ name, index, isMobile }) => (
  <div className={`text-dark-text py-2 text-[15px] first-letter font-roboto text-left
   ${!isMobile && index == 0 ? 'border-r border-b' : ""} ${!isMobile && index == 1 ? "border-b pl-3" : ""} ${!isMobile && index == 2 ? "border-r" : ""} ${!isMobile && index == 3 ? "pl-3" : ""}
   ${isMobile && index != 3 ? "border-b border-[#85858566] py-3" : ""}
  `}>
    {name}
  </div>
);

interface ServiceCardProps {
  title: string;
  attributes: string[];
  img: string;
  isMobile: boolean;
  link: string;
  index: number;
  isVisible: boolean;
  openCardIndex: number | null;
  setOpenCardIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  attributes,
  img,
  isMobile,
  link,
  index,
  isVisible,
  openCardIndex,
  setOpenCardIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const cardRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    if (isVisible) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: {
          type: "spring",
          damping: 15,
          stiffness: 80,
          delay: index * 0.1
        }
      });
    }
  }, [isVisible, controls, index]);

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenCardIndex((prev) => (prev === index ? null : index));
  };

  return (
    <motion.div
      ref={cardRef}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      className="p-[1.5px] rounded-lg h-full md:hover:bg-gradient-to-b from-gradient-light to-gradient-dark"
    >
      <div
        className="bg-[#F9F9F9] overflow-hidden flex flex-col h-full rounded-md cursor-pointer md:hover:bg-[#E9F1EC]"
        onClick={() => {
          navigate(link);
        }}
      >
        {/* Fixed aspect ratio container for all images */}
        <div className="w-full relative pb-[56.25%]">
          <img
            src={img}
            alt={title}
            className="absolute inset-0 object-cover w-full h-full object-center"
          />
        </div>
        <div className="px-2 pt-2 pb-1 md:px-5 md:pb-2 border-t border-gray-200 flex-grow">
          <h3
            role="button"
            className={`text-xl md:text-2xl leading-none font-aeonik font-semibold text-gray-800  md:mb-8 flex justify-between ${isMobile ? 'px-2' : ''}`}
            onClick={isMobile ? toggleDropdown : undefined}
          >
            {title}
            {isMobile && (
              openCardIndex === index ? <DashedLineIcon className="mb-[5px]" height={20} width={20}/> : <DownArrowIcon height={25} width={25} />
            )}
          </h3>
          {(!isMobile || openCardIndex === index) && (
            <AnimatePresence>
              <motion.div
                initial={isMobile ? { height: 0, opacity: 0 } : {}}
                animate={isMobile ? { height: 'auto', opacity: 1 } : {}}
                exit={isMobile ? { height: 0, opacity: 0 } : {}}
                transition={{ duration: 0.3 }}
                className={`${isMobile ? 'grid grid-cols-1 px-2' : 'grid grid-cols-2'}`}
              >
                {attributes.map((attribute, index) => (
                  <Feature key={index} index={index} name={attribute} isMobile={isMobile} />
                ))}
              </motion.div>
            </AnimatePresence>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const Solutions = React.forwardRef((props: Props, ref?: React.Ref<HTMLInputElement>) => {
  const isMobile = useMediaQuery('(max-width:700px)');
  const [email, setEmail] = useState<string>("");
  const [visibleCards, setVisibleCards] = useState<boolean[]>(Array(solutions.length).fill(false));
  const sectionRef = useRef<HTMLElement>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const headerInView = useInView(headerRef, { once: true, amount: 0.5 });

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.35, // Card must be 35% visible to trigger
    };

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.getAttribute('data-index'));
        if (!isNaN(index)) {
          setVisibleCards(prev => {
            const newState = [...prev];
            newState[index] = entry.isIntersecting;
            return newState;
          });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Create refs for each card and observe them
    cardRefs.current = solutions.map((_, i) => cardRefs.current[i] || null);

    const cardsElements = document.querySelectorAll('.card-container');
    cardsElements.forEach((card, index) => {
      card.setAttribute('data-index', index.toString());
      observer.observe(card);
    });

    return () => {
      cardsElements.forEach(card => observer.unobserve(card));
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      id="solutions"
      className={`flex flex-col p-5 md:p-14 bg-[#FFFFFF]`}
    >
      <div className="container mx-auto">
        <motion.div
          ref={headerRef}
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="flex text-2xl md:text-6xl font-semibold max-w-[780px] text-shade font-aeonik"
        >
          {isMobile ? (
            <p>Explore our curated research solutions</p>
          ) : (
            <p>Explore our curated research solutions</p>
          )}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={headerInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
          className={`flex ${isMobile ? 'flex-col space-y-6' : ''} justify-between md:mt-7`}
        >
          <Paragraph className="tracking-wide leading-relaxed text-circuit font-roboto max-w-[390px]">
            Multitude of ways Wokelo is revolutionizing the knowledge economy
          </Paragraph>
          <Input
            ref={ref}
            className="relative flex items-end bg-input-bg justify-between w-full max-w-md p-3 border border-input-border rounded-md h-max"
            inputClassName="w-full px-1 md:px-3 py-1 bg-input-bg font-roboto focus:outline-none text-xs md:text-[16px] focus:ring-0 rounded-md"
            buttonClass='absolute right-1.5 top-1.5 bottom-1.5 px-2 font-roboto-mono md:px-3 text-[10px] md:text-[14px] bg-nano-light text-carbon font-medium rounded-md flex items-center justify-center'
            placeholder="Your e-mail address"
            variant="nano"
            value={email}
            isMobile={isMobile}
            buttonText={["BOOK A DEMO", "BOOK A DEMO"]}
            onInput={(e) => {
              setEmail(e.currentTarget.value);
            }}
          />
        </motion.div>

        <div className="py-4 mt-7 md:mt-20">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6">
            {solutions.map((item: SolutionProps, index: number) => (
              <div key={index} className="card-container" ref={el => cardRefs.current[index] = el}>
                <ServiceCard
                  title={item.title}
                  attributes={item.attributes}
                  img={item.img}
                  isMobile={isMobile}
                  link={item.link}
                  index={index}
                  isVisible={visibleCards[index]}
                  openCardIndex={openCardIndex}
                  setOpenCardIndex={setOpenCardIndex}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
});

export default Solutions;