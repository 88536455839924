const MobileFooter = () => {
    return (
        <footer className="w-full bg-shade text-mint py-2 pt-5 px-4 md:px-12 mt-auto">
            <div className="max-w-7xl mx-auto flex flex-col space-y-8">
                <div className="flex flex-col md:flex-row justify-between items-start">
                    <div className="mb-4 md:mb-0">
                        <h2 className="text-3xl md:text-5xl font-semibold font-aeonik text-ash">
                            Say Hello to the Future of Work
                        </h2>
                        <p className="text-sm mt-2 font-roboto text-mint">
                            Get ready to supercharge your research
                        </p>
                    </div>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 text-xs text-ash font-mono uppercase">
                    <div className="mb-9">
                        <h3 className="font-bold mb-4 text-metal">PRODUCT</h3>
                        <ul className="space-y-2">
                            <li><a href="/#howitworks">HOW IT WORKS</a></li>
                            <li><a href="/#solutions">SOLUTIONS</a></li>
                            <li><a href="https://insights.wokelo.ai/" target="_blank">Insights</a></li>
                        </ul>
                    </div>
                    <div className="mb-9">
                        <h3 className="font-bold mb-4 text-metal">SOLUTIONS</h3>
                        <ul className="space-y-2">
                            <li><a href="/for-private-equity">Private Equity</a></li>
                            <li><a href="/for-investment-banking">investment banking</a></li>
                            <li><a href="/for-corporate-strategy">Corporate strategy</a></li>
                            <li><a href="/for-venture-capital">venture capital</a></li>
                            <li><a href="/for-management-consulting">management consulting</a></li>
                            <li><a href="/for-fund-of-funds">Fund-of-Funds</a></li>
                        </ul>
                    </div>
                    <div className="mb-2">
                        <h3 className="font-bold mb-4 text-metal">COMPANY</h3>
                        <ul className="space-y-2">
                            <li><a href="/about-us">About us</a></li>
                            <li><a href="/legals">legal</a></li>
                            <li><a href="https://jobs.ashbyhq.com/wokelo-ai">Careers</a></li>
                        </ul>
                    </div>
                    <div className="mb-2">
                        <h3 className="font-bold mb-4 text-metal">SOCIAL</h3>
                        <ul className="space-y-2">
                            <li><a href="https://www.linkedin.com/company/wokelo-ai" target="_blank">Linkedin</a></li>
                            <li><a href="https://x.com/WokeloAi" target="_blank">X</a></li>
                        </ul>
                    </div>
                </div>

                <div className="border-t text-ash border-mint pt-4 pb-4 flex flex-col md:flex-row justify-between text-xs font-mono">
                    <p className="mb-2 md:mb-0">WOKELO INC. SEATTLE, WA, USA</p>

                    <p>
                        <a target="_blank" href="mailto:support@wokelo.ai">
                            SUPPORT@WOKELO.AI
                        </a>
                    </p>
                </div>

                <div className="flex flex-col items-center mt-12">
                    <img
                        src="/Logo/Logo-lg.svg"
                        alt="Wokelo Logo"
                        className="mb-6"
                    />
                    <p className="text-metal text-xs text-center font-mono p-1" style={{ fontSize: '0.6rem' }}>
                        &copy; 2025 Wokelo AI | Wokelo Inc. All rights reserved
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default MobileFooter;
