import { useEffect } from "react";

const RedirectToPE = () => {
  useEffect(() => {
    window.location.href="https://signup.wokelo.ai/wokelo-for-pe/";
  }, []);

  return null;
};

export default RedirectToPE;
