import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, MotionProps } from 'framer-motion';

// Define the type for testimonial data
interface Testimonial {
    id: number;
    name: string;
    role: string;
    company: string;
    description: string;
    profileImg: string;
    img?: string;
}

interface TestimonialCarouselProps {
    testimonials: Testimonial[];
}

const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({ testimonials }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [direction, setDirection] = useState<'next' | 'prev'>('next');
    const [cardHeight, setCardHeight] = useState<number>(0);
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    // Calculate indices for visible cards
    const getVisibleIndices = () => {
        const secondIndex = (currentIndex + 1) % testimonials.length;
        const thirdIndex = (currentIndex + 2) % testimonials.length;

        return {
            primary: currentIndex,
            secondary: secondIndex,
            tertiary: thirdIndex
        };
    };

    const { primary, secondary, tertiary } = getVisibleIndices();

    // Function to handle navigation with smoother transitions
    const navigate = (dir: 'next' | 'prev') => {
        // Set animation direction
        setDirection(dir);

        // Prevent rapid clicking that can cause animation issues
        const carouselContainer = document.querySelector('.carousel-container');
        if (carouselContainer) {
            carouselContainer.classList.add('pointer-events-none');
            setTimeout(() => {
                carouselContainer.classList.remove('pointer-events-none');
            }, 500); // Re-enable after animation completes
        }

        if (dir === 'next') {
            setCurrentIndex((prevIndex) =>
                prevIndex + 1 >= testimonials.length ? 0 : prevIndex + 1
            );
        } else {
            setCurrentIndex((prevIndex) =>
                prevIndex - 1 < 0 ? testimonials.length - 1 : prevIndex - 1
            );
        }
    };

    // Function to measure and update card heights
    const updateCardHeights = () => {
        // Reset refs array
        cardRefs.current = cardRefs.current.slice(0, testimonials.length);

        let maxHeight = 0;

        // Get the content heights of all cards
        cardRefs.current.forEach(ref => {
            if (ref) {
                const contentHeight = ref.scrollHeight;
                maxHeight = Math.max(maxHeight, contentHeight);
            }
        });

        // Set the maximum height
        if (maxHeight > 0 && maxHeight !== cardHeight) {
            setCardHeight(maxHeight);
        }
    };

    // Initial measurement and on testimonial/index change
    useEffect(() => {
        // Wait for the DOM to update before measuring
        const timer = setTimeout(() => {
            updateCardHeights();
        }, 100);

        return () => clearTimeout(timer);
    }, [testimonials, currentIndex]);

    // Set up ResizeObserver for handling window resize events
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            // Debounce resize calculations to avoid too many updates
            if (containerRef.current) {
                updateCardHeights();
            }
        });

        // Observe the container element
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (containerRef.current) {
                resizeObserver.disconnect();
            }
        };
    }, []);

    return (
        <div className="w-full relative carousel-container" ref={containerRef}>
            <div className="flex flex-col lg:flex-row lg:justify-between relative">
                {/* Desktop view: side by side */}
                <div className="hidden lg:flex w-full h-max relative" style={{ minHeight: cardHeight > 0 ? `${cardHeight}px` : '252px' }}>
                    <AnimatePresence initial={false} custom={direction}>
                        {/* Primary Card */}
                        <DesktopCard
                            key={`desktop-${testimonials[primary].id}`}
                            testimonial={testimonials[primary]}
                            direction={direction}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            ref={(el) => (cardRefs.current[primary] = el)}
                            cardHeight={cardHeight}
                        />
                        <DesktopCard
                            key={`desktop-${testimonials[secondary].id}`}
                            testimonial={testimonials[secondary]}
                            direction={direction}
                            initial="enter"
                            animate="secondary"
                            exit="exit"
                            ref={(el) => (cardRefs.current[secondary] = el)}
                            cardHeight={cardHeight}
                        />

                        {/* Tertiary Card (partially visible) */}
                        {testimonials.length > 2 && (
                            <DesktopCard
                                key={`desktop-${testimonials[tertiary].id}`}
                                testimonial={testimonials[tertiary]}
                                direction={direction}
                                initial="enter"
                                animate="tertiary"
                                exit="exit"
                                ref={(el) => (cardRefs.current[tertiary] = el)}
                                cardHeight={cardHeight}
                            />
                        )}
                    </AnimatePresence>
                </div>

                {/* Mobile view: stacked with two visible testimonials */}
                <div className="lg:hidden w-full flex flex-col space-y-4 mb-8 overflow-hidden">
                    <AnimatePresence initial={false} custom={direction} mode="wait">
                        <div key={`mobile-container-${currentIndex}`} className="flex flex-col space-y-4">
                            {/* Primary Card */}
                            <MobileCard
                                key={`mobile-${testimonials[primary].id}`}
                                testimonial={testimonials[primary]}
                                ref={(el) => (cardRefs.current[primary] = el)}
                                custom={direction}
                                initial="enter"
                                animate="visible"
                                exit="exit"
                            />

                            {/* Secondary Card */}
                            <MobileCard
                                key={`mobile-${testimonials[secondary].id}`}
                                testimonial={testimonials[secondary]}
                                ref={(el) => (cardRefs.current[secondary] = el)}
                                custom={direction}
                                initial="enter"
                                animate="visible"
                                exit="exit"
                                delay={0.15}
                            />
                        </div>
                    </AnimatePresence>
                </div>
            </div>

            {/* Navigation and pagination */}
            <div className="flex justify-between items-center mt-4 md:mt-16 px-2">
                <div className="text-gray-600">
                    <span>{currentIndex + 1}</span> / <span>{testimonials.length}</span>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={() => navigate('prev')}
                        className="border-[0.5px] border-iron hover:bg-gray-300 p-2 rounded"
                        aria-label="Previous testimonial"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <button
                        onClick={() => navigate('next')}
                        className="bg-nano-light hover:bg-nano p-2 rounded text-white"
                        aria-label="Next testimonial"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
};

// Mobile Card component 
const MobileCard = React.forwardRef<HTMLDivElement, {
    testimonial: Testimonial;
    custom?: 'next' | 'prev';
    initial?: string;
    animate?: string;
    exit?: string;
    delay?: number;
}>((props, ref) => {
    const { testimonial, custom, initial, animate, exit, delay = 0 } = props;

    const mobileCardVariants = {
        enter: (direction: 'next' | 'prev') => ({
            y: 50,
            opacity: 0,
            transition: {
                duration: 0.3
            }
        }),
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 30,
                mass: 0.8,
                delay: delay
            }
        },
        exit: (direction: 'next' | 'prev') => ({
            y: -50,
            opacity: 0,
            transition: {
                duration: 0.3
            }
        })
    };

    return (
        <motion.div
            ref={ref}
            className="bg-white rounded-lg py-4 overflow-hidden"
            variants={mobileCardVariants}
            custom={custom}
            initial={initial}
            animate={animate}
            exit={exit}
        >
            <p className="text-gray-700 mb-6 break-words overflow-wrap-anywhere">{testimonial.description}</p>
            <div className="h-[1px] w-full bg-gray-200 my-4" />
            <div className="flex items-end">
                <img
                    src={testimonial.profileImg}
                    alt={testimonial.name}
                    className="w-20 h-20 rounded mr-4"
                />
                <div>
                    <p className="text-carbon text-sm font-roboto-mono">{testimonial.name}</p>
                    <p className="text-carbon/40 text-sm font-roboto-mono">{testimonial.role}</p>
                    {testimonial.img && (
                        <img
                            src={testimonial.img}
                            alt={`${testimonial.company} logo`}
                            className="h-6 mt-1"
                        />
                    )}
                </div>

            </div>
        </motion.div>
    );
});

// Desktop Card component
const DesktopCard = React.forwardRef<HTMLDivElement, {
    testimonial: Testimonial,
    direction: 'next' | 'prev',
    cardHeight?: number
} & MotionProps>(({ testimonial, direction, cardHeight, ...props }, ref) => {
    const cardVariants = {
        enter: (direction: string) => ({
            x: direction === 'next' ? '100%' : '-100%',
            opacity: 0,
            scale: 0.9,
            zIndex: 0
        }),
        center: {
            x: '0%',
            opacity: 1,
            scale: 1,
            zIndex: 2,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 30,
                mass: 0.8
            }
        },
        secondary: {
            x: '100%',
            opacity: 1,
            scale: 1,
            zIndex: 1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 30,
                mass: 0.8
            }
        },
        tertiary: {
            x: '200%',
            opacity: 0.2,
            scale: 1,
            zIndex: 0,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 30,
                mass: 0.8
            }
        },
        exit: (direction: string) => ({
            x: direction === 'next' ? '-100%' : '100%',
            opacity: 0,
            scale: 0.9,
            zIndex: 0,
            transition: {
                duration: 0.3,
                ease: [0.43, 0.13, 0.23, 0.96]
            }
        })
    };

    return (
        <motion.div
            ref={ref}
            className="bg-white rounded-lg flex flex-col justify-between absolute w-[50%] left-0 will-change-transform pr-24 overflow-hidden"
            custom={direction}
            variants={cardVariants}
            style={{ height: cardHeight && cardHeight > 0 ? `${cardHeight}px` : 'auto' }}
            {...props}
        >
            <div className=" flex-grow min-h-[300px] xl:min-h-0 overflow-hidden">
                <p className="text-shade text-lg roboto  break-words overflow-wrap-anywhere !leading-[140%]">{testimonial.description}</p>
            </div>
            <div className="mt-[24px] ">
                <div className='h-[1px] w-full bg-circuit/20 my-6' />
                <div className='flex items-end'>
                    <img
                        src={testimonial.profileImg}
                        alt={testimonial.name}
                        className="w-16 h-16 rounded mr-5"
                    />
                    <div>
                        <p className="text-carbon font-roboto-mono">{testimonial.name}</p>
                        <p className="text-carbon/40 font-roboto-mono">{testimonial.role}</p>
                    </div>
                    <div className='grow-0 w-14 shrink ml-auto'>
                        {testimonial.img && (
                            <img
                                src={testimonial.img}
                                alt={`${testimonial.company} logo`}
                                className="h-full w-full"
                            />
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    );
});

export default TestimonialCarousel;