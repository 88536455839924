import Navbar from "./Navbar";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import { useMediaQuery } from "@mui/material";
import { useRef } from "react";


const ThankYou = () => {
    const inputRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:700px)');
    return (
        <div className='relative !bg-white overflow-x-hidden' >

            <div className='relative'>
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"

                />
                <Navbar className='!bg-none' />

                    <div className="flex flex-col  h-[70vh] md:text-left p-2 md:px-14 md:py-8  items-center justify-center">
                        <h1 className="font-aeonik text-carbon text-center text-[40px] md:text-[60px] lg:text-[80px]  font-semibold w-full">Thank you!</h1>
                        <p className="flex flex-col font-roboto text-center text-[15px] md:text-[16px] text-carbon w-full mb-16">Your request has been received and a member of our team will be in touch soon!</p>
                    </div>

            </div>
            {isMobile ? <MobileFooter /> : <DesktopFooter />}
        </div>
    )
}

export default ThankYou;