import { useEffect } from "react";

const RedirectToKpmg = () => {
  useEffect(() => {
    window.location.href="https://www.wokelo.ai/for-management-consulting";
  }, []);

  return null;
};

export default RedirectToKpmg;
