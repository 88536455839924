import React, { useState, useEffect, useRef } from "react";
import { s } from "../Home/utils";
import { useMediaQuery, useTheme } from "@mui/material";
import "./Landing4.scss";
import Vimeo from "@u-wave/react-vimeo";

const ANIMATION_DURATION = 2;

const opacity = {
  1: 1,
  2: 0.6,
  3: 0.25,
  4: 0,
};

const gap = {
  1: "60vw",
  2: "57vw",
  3: "52vw",
  4: "47vw",
};

type Props = {
  setLanding?: React.Dispatch<React.SetStateAction<any>>;
  landing?: any;
  scrollHeight?: number;
  setScrollHeight?: React.Dispatch<React.SetStateAction<number>>;
  leftAligned?: boolean;
};

const TrustedByLandingPage = React.forwardRef(
  (
    {
      setLanding,
      landing,
      scrollHeight = 1,
      setScrollHeight,
      leftAligned = false,
    }: Props,
    ref?: React.Ref<HTMLElement>
  ) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const videoContainerRef = useRef<HTMLDivElement>(null);

    const [videoPaused, setVideoPaused] = useState(true);
    const [wasPlaying, setWasPlaying] = useState(false);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const scrollToVideo = () => {
      if (videoContainerRef.current) {
        const rect = videoContainerRef.current.getBoundingClientRect();
        const videoCenter = rect.top + window.scrollY + rect.height / 2;
        const windowCenter = window.innerHeight / 2;
        const scrollTo = videoCenter - windowCenter;

        window.scrollTo({
          top: scrollTo,
          behavior: "smooth",
        });
      }
    };

    const handlePlayVideo = () => {
      scrollToVideo();

      setTimeout(() => {
        setVideoPaused(false);
        setWasPlaying(false);
      }, 500);
    };

    const handleStopVideo = () => {
      document
        .getElementById("video-vimeo")
        ?.querySelectorAll("iframe")
        .forEach((v) => {
          v.src = v.src;
        });

      setVideoPaused(true);
    };

    useEffect(() => {
      if (videoContainerRef.current) {

        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        };

        observerRef.current = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting && !videoPaused) {
              handleStopVideo();
              setWasPlaying(true);
            }
            // Optional: resume playback if it comes back into view and was playing before
            // Uncomment the following to enable auto-resume:
            /*
            else if (entry.isIntersecting && wasPlaying) {
              handlePlayVideo();
              setWasPlaying(false);
            }
            */
          });
        }, options);

        observerRef.current.observe(videoContainerRef.current);
      }

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, [videoPaused]);

    const minHeight = `min-h-[calc(${gap[scrollHeight]}-20vh)]`;

    return (
      <>
        <section
          className={`w-full mt-10 md:mt-0 h-full flex flex-col bg-white-bg relative ${s.heroPaddings}`}
          style={{ paddingTop: 0, paddingBottom: leftAligned ? 60 : 0 }}
        >
          {!leftAligned && (
            <div
              className={`relative video-main flex-grow w-full self-stretch ${isMobile ? "min-h-[calc(70vw-20vh)]" : "min-h-[calc(50vw-20vh)]"
                }`}
              style={{
                width: "100%",
              }}
            >
              {videoPaused && (
                <button
                  className="absolute z-20 right-[7%] h-[64px] w-[64px] md:h-[128px] md:w-[128px] flex items-center rounded-full font-roboto-mono text-xs md:text-sm -top-[5%] justify-center bg-nano-light font-semibold tracking-wider uppercase"
                  style={{
                    outline: "10px solid #F8F8F8",
                  }}
                  onClick={handlePlayVideo}
                >
                  Watch Video
                </button>
              )}

              <div className="flex justify-center w-full h-[full] mx-auto">
                <div
                  ref={videoContainerRef}
                  id="video-container"
                  className={`video-container video-container-h-${isMobile ? 1 : scrollHeight
                    } pb-1`}
                >
                  <img
                    className="ipadDesktop"
                    src="/AppleiPadProMin.png"
                    alt=""
                  />
                  <div className="video-content">
                    <Vimeo
                      id="video-vimeo"
                      video="1066263598"
                      paused={videoPaused}
                      start={0}
                      loop
                      muted={true}
                      showPortrait
                      controls={false}
                    />
                  </div>
                  <hr
                    className={`absolute bottom-0 z-10 bottom-line bg-gradient-to-r from-gradient-dark to-gradient-light h-[1.5px] w-[85vw] ${isMobile ? "mt-[53.64vw]" : ""
                      } `}
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      </>
    );
  }
);

export default TrustedByLandingPage;