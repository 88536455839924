import Navbar from '../Navbar'
import FooterNew from '../../core/components/Footer/Footer'
import Challenges from '../../core/components/Solution/Challenges'
import HowItWorks from '../../core/components/Home/HowItWorksV2'
import WhyWokeloSolutions from '../../core/components/Solution/WhyWokeloSolutions'
import UseCases from '../../core/components/Solution/UseCases'
import Discover from '../../core/components/Solution/Discover'
import MetaTags from '../../core/components/Meta/Meta'
import { investmentBankingMeta } from '../../core/utils/meta'


const Content = {
    challenges: {
        title: <>The challenge of traditional investment banking research</>,
        subtitle: <>Gaps in company coverage, inefficient sourcing, and<br />
            lengthy due diligence processes lead to costly delays<br />
            and missed opportunities.</>,
        mobilesubtitle: <>Gaps in company coverage, inefficient sourcing, and
        lengthy due diligence processes lead to costly delays
        and missed opportunities.</>,
        image: 'Banking.png',
        points: [
            'Time wasted on manual data gathering instead of high-value deal-making',
            'Burnt-out research teams, lengthy deliverable creation, and bloated data subscription costs',
            'Blind spots in pipeline evaluation that slow down negotiations and execution',
        ]
    },

    why: {
        title: <>Wokelo automates deal sourcing, accelerates due diligence, and provides instant,
            AI-driven insights—so IB and M&A teams can focus on closing deals, not chasing data.</>,
        points: [
            'Win more deals with faster, high-quality sourcing and exclusive, differentiated insights',
            'Accelerate deal cycles on both buy and sell-side with rapid market intelligence, AI-powered due diligence, and automated CIM creation',
            'Scale expertise and expand deal coverage across sectors and geographies—without increasing headcount or workload',
        ],
        logos: ['/ticker/guggenheim-sol.svg', '/ticker/ey-solutions.svg', '/ticker/kpmg-solutions.svg', '/ticker/insight-solutions.svg', '/ticker/berkshire-solutions.svg', '/ticker/google-solutions.svg']
    },
    usecases: {
        title: 'Top Wokelo Use Cases for Investment Banking',
        usecases: [
            {
                title: 'Higher productivity for buy-side advisory',
                left: {
                    badge: 'Target Identification',
                    points: [
                        'Market maps & target companies',
                        'Market dynamics & shifts',
                        'Company profile & benchmarks'
                    ]
                },
                right: {
                    badge: 'Deep dive analysis',
                    points: [
                        'Supplier & customer analysis',
                        'Employee & customer reviews',
                        'Financial & operational insights',
                    ]
                }
            },
            {
                title: 'Accelerated sell-side decision making',
                left: {
                    badge: 'Identify & engage buyers',
                    points: [
                        'Company research',
                        'Executive stakeholder research',
                        'Meeting briefings & preparation'
                    ]
                },
                right: {
                    badge: 'Coordinate due diligence',
                    points: [
                        'CIMs / investment memos',
                        'Data room synthesis',
                        'Business case input'
                    ]
                }
            }
        ]
    }
}

function SolutionsPageIB() {


    return (
        <div className='relative !bg-white overflow-x-hidden' >
            <MetaTags metaProps={investmentBankingMeta} />

            <div className='relative'>
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"

                />

                <Navbar className='!bg-none' />
                <div className='w-3/4 mx-auto text-center min-h-[280px] md:min-h-[500px] flex justify-center items-center'>
                    <h1 className='text-carbon text-[40px] lg:text-[80px] font-semibold font-aeonik leading-[40px] lg:leading-[72px]'>
                        Wokelo for Investment Banking
                    </h1>

                </div>
            </div>
            <Challenges {...Content.challenges} />
            <WhyWokeloSolutions {...Content.why} />
            <Discover />
            <UseCases {...Content.usecases} />
            <HowItWorks />
            <FooterNew />
        </div>
    )
}

export default SolutionsPageIB