import React, { useState } from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import StarIcon from "../../icons/Star";
import "./howItWorks/howItWorks.css";
import Input from "../Input";
import { Player } from '@lottiefiles/react-lottie-player';
import { useMediaQuery } from "@mui/material";

type Props = { className?: string, bookDemoLabel?: string, showCalendly?: boolean, routeAfterSubmit?: boolean };

const HowItWorks = React.forwardRef((props: Props, ref: any) => {
  const { className = '', bookDemoLabel = '', showCalendly = true, routeAfterSubmit = true } = props
  const [email, setEmail] = useState("");
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <section id="howitworks" className={`relative mx-[10px] md:mx-12 mb-12 bg-white rounded-[4px] ${className}`}>
      <div className="w-[90%] md:w-[70%] mx-auto pt-8">
        {isMobile ?
          <Player
            autoplay={true}
            loop={true}
            src={"/HowItWorks/HowItworks-mobile.json"}
          />
          :
          <Player
            autoplay={true}
            loop={true}
            src={"/HowItWorks/HowItwork.json"}
          />
        }

      </div>
    </section>
  );
});

export default HowItWorks;
