import React from 'react'
import { useMediaQuery } from "@mui/material";
// const points = [
//     'Time lost to manual data gathering instead of strategic decision-making.',
//     'Blind spots in your pipeline and portfolio, due to obscure or limited private market data',
//     'Burnt-out research teams and rising data subscription costs.',
// ]
function Challenges({
    points = [], subtitle = <></>, title = '', image = '',mobilesubtitle=<></>,
}: { title, subtitle, points, image, mobilesubtitle }) {

    const isMobile = useMediaQuery('(max-width:770px)');
    return (
        <div className='relative bg-circuit px-5 md:px-14 pt-20 pb-80  sm:!pb-96 lg:!pb-20'>
            <div className='grid grid-cols-5'>
                <div className='col-span-5 lg:col-span-2 '>
                    <h2 className='text-mint font-aeonik text-4xl font-medium leading-9'>
                        {title}
                    </h2>

                    <p className='text-clean font-roboto text-base font-normal opacity-80 mt-3 '>
                       {isMobile? mobilesubtitle : subtitle}
                    </p>


                    <div className='mt-10 lg:mt-28'>
                        <p className='uppercase font-roboto-mono text-sm font-medium text-nano-light'>
                            The real cost?
                        </p>

                        <div className='flex flex-col gap-y-3 mt-3'>
                            {points.map(point => {
                                return <div className='flex items-start w-5/5 lg:w-5/6'>
                                    <img
                                        src={"/Subtract.svg"}
                                        alt="bullet"
                                        className='w-3 h-3 mr-1 mt-1.5'
                                    />
                                    <span className='text-clean font-roboto text-base font-normal opacity-80'>
                                        {point}
                                    </span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className='col-span-5 lg:col-span-3'>
                    <div className=' absolute right-0 bottom-0 '>
                        <img src={image} className='sm:h-[400px]  lg:h-[518px] lg:w-[750px] object-scale-down object-bottom ' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Challenges