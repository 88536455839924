import React from 'react';
import { ComponentProps } from '../types/component-props';

export type TagProps = {
	icon?: React.ReactNode;
} & ComponentProps;

const Tag = ({ children, icon, className }: TagProps) => {
	return (
		<span
			className={`bg-ash font-roboto-mono tracking-wider uppercase text-circuit text-xs md:text-xs border border-w-medium-gray py-1.5 md:py-2 px-1 md:px-[10px]  flex flex-row items-center rounded-sm  leading-[14px] ${className}`}
		>
			{icon ? icon : null}

			{children}
		</span>
	);
};

export default Tag;
