export type MetaTagProps = {
  title: string;
  description: string;
  pageName: string;
};

// Meta tags for Home Page
export const homePageMeta: MetaTagProps = {
  title: "Wokelo AI Research Platform | PE, VC, Consulting Solutions",
  description:
    "Wokelo AI transforms research for PE, VC, Consulting, and Corporates. Save time, uncover insights, and make data-driven decisions. Book your demo now.",
  pageName: "",
};

// Meta tags for About Us Page
export const aboutUsMeta: MetaTagProps = {
  title: "About Wokelo AI | AI-Powered Research & Insights",
  description:
    "Discover Wokelo AI's mission to transform research with AI-driven insights. Learn how we empower industries and eliminate blind spots. Book a demo.",
  pageName: "About Us",
};

// Meta tags for Private Equity Page
export const privateEquityMeta: MetaTagProps = {
  title: "Wokelo Private Equity Research Solutions | Generate Alpha",
  description:
    "Streamline PE research with Wokelo's AI-driven insights. Enhance pipeline coverage, accelerate due diligence, and monitor portfolios proactively. Book a demo.",
  pageName: "For Private Equity",
};

// Meta tags for Venture Capital Page
export const ventureCapitalMeta: MetaTagProps = {
  title: "Wokelo VC Research Tools | Startup & Market Insights",
  description:
    "Boost VC efficiency with Wokelo. Discover startups, accelerate due diligence, and gain real-time market insights. Save time, uncover alpha. Book a demo.",
  pageName: "For Venture Capital",
};

// Meta tags for Management Consulting Page
export const managementConsultingMeta: MetaTagProps = {
  title: "Wokelo Consulting Research | Market Intelligence Solutions",
  description:
    "Transform consulting research with Wokelo. Speed up market intelligence, reduce costs, and focus on strategic insights. Enhance client engagement. Book a demo.",
  pageName: "For Management Consulting",
};

// Meta tags for Investment Banking & M&A Advisory Page
export const investmentBankingMeta: MetaTagProps = {
  title: "Wokelo IB & M&A Research | Deal Execution Tools",
  description:
    "Optimize IB and M&A research with Wokelo. Improve pipeline evaluation, speed up due diligence, and close deals faster. Book a demo today.",
  pageName: "For Investment Banking",
};

// Meta tags for Corporate Strategy Page
export const corporateStrategyMeta: MetaTagProps = {
  title: "Wokelo Corporate Research | Strategy & Market Insights",
  description:
    "Empower corporate teams with Wokelo. Accelerate due diligence, gain market insights, and drive strategic decisions. Streamline research now.",
  pageName: "For Corporate Strategy",
};

// Meta tags for Fund-of-Funds Page
export const fundOfFundsMeta: MetaTagProps = {
  title: "Wokelo Fund-of-Funds Research | Portfolio Insights",
  description:
    "Enhance Fund-of-Funds research with Wokelo. Explore new markets, monitor portfolios, and get timely market alerts. Generate alpha. Book a demo.",
  pageName: "For Fund of Funds",
};
