import React from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from "../../icons/Check";
import FileIcon from "../../icons/File";
import DetectiveIcon from "../../icons/Detective";
import LockIcon from "../../icons/Lock";
import ShieldCheckIcon from "../../icons/ShieldCheck";

type Props = {};

const Soc2 = React.forwardRef((props: Props, ref?: any) => {
  const matches = useMediaQuery("(min-width:1300px)");
  const isMobile = useMediaQuery('(max-width:700px)');

  return (
    <section id="soc2" className="relative bg-[#202020] p-5 ">
      <div className={`container mx-auto flex ${isMobile ? 'flex-col' : ''} justify-between py-6 md:py-14 mt-6`}>
        <div className="w-full grid grid-cols-1">
          <div className="flex flex-col">
            <h2 className="text-clean font-aeonik font-semibold text-3xl md:text-6xl !leading-[90%] tracking-wide max-w-[547px] mb-10">
              Secure and compliant
            </h2>
            <div className="space-y-4">
              <Paragraph className="font-roboto  w-[100% ] md:w-8/12 !leading-[130%] text-ash text-sm md:text-lg">
                We service highly regulated financial services, banks and Fortune 100 firms,
                who trust Wokelo with their proprietary data.
              </Paragraph>
              <Paragraph className="font-roboto  w-[100%] md:w-8/12 !leading-[130%] text-ash text-sm md:text-lg">
                With advanced encryption and robust protection protocols,
                your information stays private and shielded.
              </Paragraph>
            </div>
          </div>
          <div className={` flex w-full ${isMobile ? 'flex-col' : ''} items-center mt-6 my-8 md:my-2  md:mt-20 `}>
            <div
              className="w-full flex gap-6">
              <div className="w-max flex justify-start">
                <picture>
                  <source srcSet="/soc.png" type="image/webp" />
                  <img
                    src="/soc.png"
                    alt="Hero"
                    className="z-30 object-scale-down w-[64px] h-auto my-auto"
                  />
                </picture>
              </div>
              <p className="text-mint my-auto mx-2 !leading-[130%] text-sm md:text-base">
                Your data is {isMobile ? <></> : <br />} protected {isMobile ? <br /> : <></>} with {isMobile ? <></> : <br />} SOC 2
              </p>
            </div>
          </div>
        </div>

        <div
          className={` w-full flex flex-wrap flex-col gap-5 items-end `}
        >
          <hr className=" bg-gradient-to-r from-gradient-light to-gradient-dark w-full h-[1px] border-0" />
          <div className="w-full ">
            {isMobile ?
              <div className="w-full flex flex-row justify-start items-start gap-4 md:gap-5">
                <h1 className="text-iron mr-6 md:mr-20 mt-4">01</h1>
                <div className="flex flex-col">
                  <h2 className="text-lg  md:text-2xl text-ash mt-4 ">
                    Private workspace
                  </h2>
                  <div className="mt-2 md:mt-2">
                    {["End to end encryption", "Enterprise grade cloud security"]?.map(
                      (item) => (
                        <div className="flex flex-start items-center gap-2 text-ash my-2">
                          <img
                            src={"/Subtract.svg"}
                            alt="bullet"
                            style={{
                              width: '11px',
                              height: '11px',
                            }}
                          />
                          <p className="text-sm text-ash">{item}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              :
              <div className="w-full grid grid-cols-[max-content_1fr_1fr] gap-4 md:gap-5 mb-2">
                <h1 className="text-iron mr-6 md:mr-20 mt-4">01</h1>
                <h2 className="text-lg md:[180px] lg:w-[180px] md:text-2xl font-medium text-ash mt-4 !leading-[90%]">
                  Private <br /> workspace
                </h2>
                <div className="mt-5 md:mt-2">
                  {["End to end encryption", "Enterprise grade cloud security"]?.map(
                    (item) => (
                      <div className="flex flex-start items-start gap-4 text-w-light-white my-2">
                        <div className="p-1">
                          <img
                            src={"/Subtract.svg"}
                            alt="bullet"
                            style={{
                              width: '12px',
                              height: '12px',
                            }}
                          />
                        </div>
                        <p className="text-md text-ash">{item}</p>
                      </div>
                    )
                  )}

                </div>
              </div>
            }

          </div>

          <hr className=" bg-gradient-to-r from-gradient-light to-gradient-dark w-full h-[1px] border-0" />
          <div className="w-full  ">
            {isMobile ?
              <div className="w-full flex flex-row justify-start items-start gap-4 md:gap-5">
                <h1 className="text-iron mr-6 sm:mr-20 mt-4">02</h1>
                <div className="flex flex-col">
                  <h2 className="text-lg  md:text-2xl text-ash mt-4 ">
                    Internal data protection
                  </h2>
                  <div className="mt-2 md:mt-4">
                    {["Customer’s internal data not stored", "Not used for model training"]?.map(
                      (item) => (
                        <div className="flex flex-start items-center gap-2 text-ash my-2">
                          <img
                            src={"/Subtract.svg"}
                            alt="bullet"
                            style={{
                              width: '11px',
                              height: '11px',
                            }}
                          />
                          <p className="text-sm text-ash">{item}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              :
              <div className="w-full grid grid-cols-[max-content_1fr_1fr] gap-4 md:gap-5 mb-2">
                <h1 className="text-iron mr-6 md:mr-20 mt-4">02</h1>
                <h2 className="text-lg md:[180px] lg:w-[180px] md:text-2xl font-medium text-ash mt-4 !leading-[90%]">
                  Internal data <br /> protection
                </h2>
                <div className="mt-5 md:mt-2">
                  {["Customer’s internal data not stored", "Not used for model training"]?.map(
                    (item) => (
                      <div className="flex flex-start items-start gap-4 text-w-light-white my-2">
                        <div className="p-1">
                          <img
                            src={"/Subtract.svg"}
                            alt="bullet"
                            style={{
                              width: '12px',
                              height: '12px',
                            }}
                          />
                        </div>
                        <p className="text-md text-ash">{item}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            }

          </div>
          <hr className=" bg-gradient-to-r from-gradient-light to-gradient-dark w-full h-[1px] border-0" />
          <div className="w-full  ">
            {isMobile ?
              <div className="w-full flex flex-row justify-start items-start gap-4 md:gap-5">
                <h1 className="text-iron mr-6 md:mr-20 mt-4">03</h1>
                <div className="flex flex-col">
                  <h2 className="text-lg  md:text-2xl text-ash mt-4">
                    Data  privacy
                  </h2>
                  <div className="mt-2 md:mt-4">
                    {["Data stream disaggregation", "Controlled access"]?.map(
                      (item) => (
                        <div className="flex flex-start items-center gap-2 text-ash my-2">
                          <img
                            src={"/Subtract.svg"}
                            alt="bullet"
                            style={{
                              width: '11px',
                              height: '11px',
                            }}
                          />
                          <p className="text-sm text-ash">{item}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              :
              <div className="w-full grid grid-cols-[max-content_1fr_1fr] gap-4 md:gap-5 ">
                <h1 className="text-iron mr-6 md:mr-20 mt-4">03</h1>
                <h2 className="text-lg md:[180px] lg:w-[180px] md:text-2xl font-medium text-ash mt-4 !leading-[90%]">
                  Data <br /> privacy
                </h2>
                <div className="mt-5 md:mt-2">
                  {["Data stream disaggregation", "Controlled access"]?.map(
                    (item) => (
                      <div className="flex flex-start items-start gap-4 text-w-light-white my-2">
                        <div className="p-1">
                          <img
                            src={"/Subtract.svg"}
                            alt="bullet"
                            style={{
                              width: '12px',
                              height: '12px',
                            }}
                          />
                        </div>
                        <p className="text-md text-ash">{item}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            }

          </div>


        </div>
      </div>
    </section>
  );
});

export default Soc2;
