import React, { useState } from "react";
import Heading from "../Heading";

import {
  customers,
} from "./utils";
import { useMediaQuery, useTheme } from "@mui/material";
// import Carousel from "react-multi-carousel";
import "./WhyCustomerLoveWokeloCarousel.scss";
import TestimonialCarousel from "../Carousel/Carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1300 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1100 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1100, min: 750 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 1,
  },
};

type Props = {
  title?: string;
  subtitle?: string | React.ReactNode;
  titleClassName?: any;
  subTitleClassName?: any;
  customTestimonials?: any;
};

const WhyCustomerLovesWokeloCarousel = React.forwardRef(
  (
    {
      titleClassName,
      subTitleClassName,
      customTestimonials = customers,
      title = "What our customers love",
      subtitle = "Leading professionals are leveraging Wokelo to get ahead of the curve",
    }: Props,
    ref?: any
  ) => {
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
      <section className={`flex flex-col overflow-x-hidden p-6 md:p-14 bg-[#FFFFFF]`}>
        <div className="container mx-auto">
          <div className="flex justify-between  items-start text-shade font-aeonik">
            <Heading className={`${titleClassName} text-[25px]  md:text-[60px] font-semibold mt-4 `}>{title}</Heading>
          </div>
          <div className="my-4 md:my-[60px] relative">
            <TestimonialCarousel testimonials={customTestimonials} />
          </div>
        </div>
      </section>
    );
  }
);

export default WhyCustomerLovesWokeloCarousel;