import Navbar from "./Navbar";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";
import { useMediaQuery } from "@mui/material";
import { useState, useRef } from "react";
import TrustedBy from "../core/components/HomePage/TrustedBy";
import TrustedByContact from "../core/components/TrustedByContact";
import WhyCustomerLovesWokeloCarousel from "../core/components/Home/WhyCustomerLoveWokeloCarouselV2";
import RequestAccessForm from "../core/components/RequestAccessForm/RequestAccessForm";

const logos = ['/ticker/kpmg-solutions.svg', '/ticker/berkshire-solutions.svg', '/ticker/ey-solutions.svg', '/ticker/bcapital-solutions.svg', '/ticker/draper-solutions.svg', '/ticker/google-solutions.svg', '/ticker/siemens-solutions.svg']


const ContactUs = () => {
    const inputRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:700px)');
    return (
        <div className='relative !bg-white'>
            <div className='relative bg-[#F5F5F5]'
                style={{
                    backgroundImage: "url('/solution-bg-light.png')",
                    backgroundRepeat: "repeat-y",
                    backgroundSize: isMobile ? "500%" : "100%",
                }}>
                {/* <img
                    src="/solution-bg-light.png"
                    alt="Hero"
                    className="object-scale-down w-full h-auto my-auto absolute top-0 "
                /> */}
                <Navbar className='!bg-none' />

                <div
                    className="grid md:grid-cols-2 w-full p-3 md:px-14 md:py-4 items-center relative">
                    <div className="flex flex-col pr-3 text-center md:text-left">
                        <div className=" bg-ash font-roboto mb-4 p-1 px-3 text-xs md:text-md md:w-max text-center font-medium rounded-md border-b border-gradient-dark  inline-block mt-5 md:mt-1">SPEAK WITH AN EXPERT</div>
                        <h1 className="font-aeonik text-carbon text-3xl md:text-4xl lg:text-6xl my-5 font-semibold w-full">A new research experience is round the corner</h1>
                        <p className="flex flex-col font-roboto text-sm md:text-md text-carbon w-full my-2">Thanks for your interest in Wokelo. Please take a minute to share the following information, and our team will reach for a 1:1 consultation on how Wokelo can power your research needs.</p>
                    </div>
                    {/* form element */}
                    <div className="flex justify-end">

                        <div className="w-full md:w-[70%] ">

                            <RequestAccessForm />
                        </div>
                    </div>
                </div>
            </div>
            {/* <TrustedBy /> */}
            <div className='border-t-iron border-opacity-30 border-t-[1px] p-6 md:px-14 md:pt-10'>
                <TrustedByContact logos={logos} />
            </div>
            <WhyCustomerLovesWokeloCarousel />
            {isMobile ? <MobileFooter /> : <DesktopFooter />}
        </div>
    )
}

export default ContactUs;