export const industryList = [
  "Academia",
  "Accelerator / Incubator",
  "Asset Manager",
  "Corporate Strategy",
  "Corporate Venture Capital",
  "Family Office",
  "Fund of Funds",
  "Hedge Funds",
  "Investment Banking",
  "Management Consulting",
  "Private Equity",
  "Startup",
  // "Venture Capital",
  "Venture Capital (pre-seed / seed)",
  "Venture Capital (series A+)",
  "Venture Debt",
  "Others",
];
