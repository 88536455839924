import { useEffect } from "react";

const RedirectToCareers = () => {
  useEffect(() => {
    window.location.href="https://jobs.ashbyhq.com/wokelo-ai";
  }, []);

  return null;
};

export default RedirectToCareers;
