import React from 'react'
import TrustedBySolutions from './TrustedBySolutions'


function WhyWokeloSolutions({ logos = [], points = [], title = '' }: { title, points, logos }) {
    return (
        <div className='bg-white relative p-5 md:px-14 md:pt-14 md:pb-[27px] '>
            <div className='grid grid-cols-2 '>
                <div className='col-span-2 lg:col-span-1 !leading-[24px] w-full lg:w-3/5 text-circuit font-aeonik text-[18px] lg:text-[21px] font-medium'
                  style={{letterSpacing:'-1%'}}
                >
                    {title}
                </div>
                <div className='col-span-2 lg:col-span-1 mt-8 lg:mt-0'>
                    <div className='flex flex-col space-y-10'>
                        {
                            points.map((e, i) => {
                                return <div key={e} className=' border-t-[0.5px] border-gradient-light pt-5  flex items-start justify-between space-x-16'>
                                    <span className='font-roboto-mono text-sm text-gradient-light'>
                                        (0{i + 1})
                                    </span>
                                    <span className='text-[#181B20] font-roboto text-base leading-5 opacity-80 pr-0 lg:pr-16'>
                                        {e}
                                    </span>
                                </div>
                            })
                        }
                    </div>
                </div>

            </div>
            <div className='border-t-iron border-opacity-30 border-t-[1px] pt-7 mt-20'>
                <TrustedBySolutions logos={logos} />
            </div>

        </div>
    )
}

export default WhyWokeloSolutions