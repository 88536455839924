import React, { useEffect, useState,useRef } from "react";
import { featureSections, s } from "./utils";
import Heading2 from "../Heading2";
import Heading3 from "../Heading3";
import Tag from "../Tag";
import { LinearProgress } from "@mui/material";
import "./featureSection/featureSectionV2.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion"
import { DownArrowIcon } from "../../icons/icons";
import { CustomSelect } from "./CustomSelect";
type Props = {
  isMobile?: boolean,
};

const TabContent = ({ title, name, quotes, tags, image }) => {
  const isMobile = useMediaQuery('(max-width:770px)');
  return (
    <div className={`my-0 gap-5  scroll-mt-80 flex ${isMobile?"flex-col":"flex-row mt-52"} items-center`}>
      <div className={`p-4  ${isMobile?"flex-col":"w-1/2 p-0"} `}>
        <h2 className="font-medium text-3xl lg:text-4xl text-white font-aeonik">{title}</h2>
        <div className="mt-5 md:mt-4 xl:mt-6 flex flex-row w-[100%] flex-wrap gap-2">
          {tags.map((t) => (
            <Tag key={t}>{t}</Tag>
          ))}
        </div>
        <div className={`w-full flex  ${isMobile?"flex-col":"flex-row"} items-start `}>

          <div className={` ${isMobile?"w-full":"w-[80%]"} flex mt-5 md:mt-6 h-auto`}>

            <div className="flex flex-col items-start justify-center">
              <div className={` flex-col ${isMobile?" ":"flex"}`}>
                <ul
                  style={{ listStyleType: 'none' }}
                  className="list-disc font-roboto text-clean/80 list-outside text-base md:text-md tracking-wide leading-[1.2rem]">
                  {quotes?.map((q) => (
                    <li
                      key={q.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px',
                      }}
                    >
                      <img
                        src={"/Subtract.svg"}
                        alt="bullet"
                        style={{
                          width: '12px',
                          height: '12px',
                          marginRight: '8px',
                        }}
                      />
                      {q.quote}
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div
        className={`relative  md:rounded-md ${isMobile?"w-full h-auto":"w-[40%] h-[400px]"} flex flex-grow items-center justify-center`}
      >
        <div className=" rounded-md p-4 md:p-0">
          <picture>
            <source srcSet={image} type="image/webp" />
            <img
              src={image}
              alt="Hero"
              className="z-30 w-[580px] object-scale-down"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

const FeatureSection = React.forwardRef((props: Props, ref: any) => {
  const [activeTab, setActiveTab] = useState(1);
  const [scrollY, setScrollY] = useState(0);
  const tabRefs = useRef({});

  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:770px)');
  function logit() {
    setScrollY(window.scrollY);
  }

  useEffect(() => {
    if (!props.isMobile) {
      if (scrollY < 1600) {
        if (activeTab != 1) {
          setActiveTab(1);
        }
      } else if (scrollY >= 1600 && scrollY < 2000) {
        if (activeTab != 2) {
          setActiveTab(2);
        }
      } else if (scrollY >= 2000 && scrollY < 2400) {
        if (activeTab != 3) {
          setActiveTab(3);
        }
      } else if (scrollY >= 2400 && scrollY < 2600) {
        if (activeTab != 4) {
          setActiveTab(4);
        }
      } else if (scrollY >= 2600 && scrollY < 3200) {
        if (activeTab != 5) {
          setActiveTab(5);
        }
      }
    }

    console.log(scrollY);
  }, [scrollY]);

  const changeActiveTab = (activeTab) => {
    if (activeTab == 1) {
      const element = document.getElementById("product");
      const el = document.getElementById("product-tab");
      console.log(el?.offsetTop);

      const offsetTop = element?.offsetTop;
      setScrollY(offsetTop ? offsetTop + 100 : 1500);
      window.scrollTo(0, offsetTop ? offsetTop + 100 : 1500);
    } else if (activeTab == 2) {
      setScrollY(1700);
      window.scrollTo(0, 1700);
    } else if (activeTab == 3) {
      setScrollY(2100);
      window.scrollTo(0, 2100);
    } else if (activeTab == 4) {
      setScrollY(2500);
      window.scrollTo(0, 2500);
    } else if (activeTab == 5) {
      setScrollY(2600);
      window.scrollTo(0, 2600);
    }
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  const TabClick=(id)=>{
     setActiveTab(id);
     setTimeout(() => {
        tabRefs.current[id]?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 10);
  }

  return (
    <>
      {props.isMobile ?
        <section className={`flex flex-col bg-shade mb-10`} >
          <h1 className="text-white text-3xl font-aeonik my-2  p-4"> One Platform.<br /> Infinite Insights </h1>

          <div className="w-full">
            {featureSections.map((section) => (
              <div key={section.id} ref={(el) => (tabRefs.current[section.id] = el)}>

                <div
                  className={`bg-shade border-t border-b border-iron text-clean font-mono uppercase px-5 py-3.5 w-full flex justify-between items-center cursor-pointer ${activeTab === section.id ? "" : ""}`}
                  onClick={() => TabClick(section.id)}
                >
                  {section.name}
                  {activeTab === section.id ?<></>:<DownArrowIcon />}
                </div>

                {activeTab === section.id && (
                  <div className="et_pb_row z-10 mt-3 sm:px-3">
                    <TabContent
                      title={section.title}
                      name={section.name}
                      quotes={section.quotes}
                      tags={section.tags}
                      image={section.img}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>


        </section>
        :

        <section className={`container mx-auto flex flex-col px-4 pb-8 lg:pb-16 bg-shade`} id="product">
          <div className="scroll-tabs relative flex flex-col">
            <div className="tab pt-0 md:pt-[24px] z-20 sticky top-0 bg-shade" id="product-tab">
              <h1 className="text-clean text-4xl lg:text-4xl pt-4 lg:pt-4 text-center font-aeonik mb-4 lg:mb-4 mx-auto"> One Platform. Infinite Insights </h1>
              <div className="tab grid grid-cols-5 ">
                {featureSections?.map((item, index) => (
                  <button
                    key={index}
                    className={`text-left flex flex-col justify-end w-full font-mono md:text-sm lg:text-base uppercase text-[#D6E7DB] cursor-pointer space-y-7 ${activeTab === item?.id ? "" : "opacity-50"
                      }`}
                    onClick={() => changeActiveTab(item?.id)}
                  >
                    <span className="px-3">{isMobile ? item?.mobileName : item?.name}</span>
                    <div className="relative w-full h-[2px]">
                      <div className="absolute top-0 left-0 w-full h-full bg-gray-500 opacity-50" />
                      {activeTab === item?.id && (
                        <AnimatePresence mode="popLayout" initial={false} presenceAffectsLayout>
                          <motion.div
                            transition={{
                              delay: 0,
                              ease: "linear"
                            }}
                            layoutId="bar"
                            layout
                            className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-gradient-dark to-gradient-light"
                          />
                        </AnimatePresence>
                      )}
                    </div>
                  </button>
                ))}

              </div>

              {/* <div className="w-full mt-4">
                <LinearProgress
                  variant="determinate"
                  value={activeTab === 1 ? 20 : activeTab === 2 ? 40 : activeTab === 3 ? 60 : activeTab === 4 ? 80 : 100}
                  sx={{
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "white",
                    },
                  }}
                />
              </div> */}
            </div>
            <div className="et_pb_row z-10 mt-3 ">
              <div ref={ref}>
                <TabContent
                  title={featureSections[activeTab - 1]?.title}
                  name={featureSections[activeTab - 1]?.name}
                  quotes={featureSections[activeTab - 1]?.quotes}
                  tags={featureSections[activeTab - 1]?.tags}
                  image={featureSections[activeTab - 1]?.img}
                />
              </div>
            </div>
          </div>
        </section >
      }
    </>
  );
});

export default FeatureSection;
