import React, { useState } from 'react';
import Input from '../InputV2';
interface EmailInputProps {
  isMobile: boolean;
  ref: any
}

const EmailInput = ({ isMobile, ref }: EmailInputProps) => {
  const [email, setEmail] = useState('');

  return (

    <Input
      ref={ref}
      className="relative flex items-center  bg-white justify-between mx-auto w-full max-w-md p-3 border border-gray-300 rounded-md"
      inputClassName="w-full px-2 md:px-3 py-1 font-roboto  focus:outline-none text-sm md:text-[16px] focus:ring-0  rounded-md"
      buttonClass='absolute  right-1.5 top-1.5 bottom-1.5 font-roboto-mono px-2  md:px-4 text-xs md:text-[14px] bg-nano-light text-carbon font-medium rounded-[4px] flex items-center justify-center'
      placeholder="Your e-mail address"
      variant="nano"
      value={email}
      isMobile={isMobile}
      buttonText={["BOOK A DEMO", "BOOK A DEMO"]}
      onInput={(e) => {
        setEmail(e.currentTarget.value);
      }}
    />


  );
};

export default EmailInput;
