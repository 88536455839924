import React from "react";
import { ButtonHTMLAttributes } from "react";

export type ButtonVariant =
  | "primary"
  | "transparent"
  | "outlined"
  | "secondary"
  | "cream"
  | "nano";

export const buttonVariants: { [key in ButtonVariant]: string } = {
  primary: "bg-w-black text-w-light-white shadow-sm shadow-gray-300/30",
  secondary: "bg-w-light-white text-w-black shadow-gray-300/30",
  cream: "bg-w-cream text-w-black shadow-gray-300/30",
  transparent: "text-w-purple hover:bg-gray-200",
  nano:"bg-nano-light",
  outlined:
    "bg-[#202020] border border-w-light-white text-white hover:shadow-md hover:shadow-gray-300/30",
};

export type ButtonProps = {
  variant?: ButtonVariant;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ variant, className, children, ...props }: ButtonProps) => {
  return (
    <button
      className={`${className} ${ buttonVariants[variant ?? "primary"]} ${props.disabled ? "cursor-not-allowed" : ""} `}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
