import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useMediaQuery, useOnClickOutside } from "usehooks-ts";
import { ComponentProps } from "../types/component-props";
import Button from "./Button";
import "../../App.css";
import ClockIcon from "../icons/Clock";
import CloseIcon from "../icons/Close";
import WokeloWhiteIcon from "../icons/WokeloWhite";
import Input from "./Input";
import WokeloIcon from "../icons/Wokelo";
import { useState } from "react";
import tracker from "../hooks/useAnalyticsEventTracker";

export type MenuLinkProps = {
  title: string;
  href: string;
  target?: string;
  onClick?: () => void;
} & ComponentProps;

export const MenuLink = ({
  href,
  title,
  onClick,
  target,
  className,
}: MenuLinkProps) => {
  return (
    <a
      href={href}
      target={target}
      className={`w-full flex justify-center my-3 px-[16px] py-[14px] ${className}`}
      onClick={() => onClick && onClick()}
    >
      <h2 className={`text-[24px] leading-[16px] font-normal text-[#000]`}>
        {title}
      </h2>
    </a>
  );
};

export type MenuProps = {
  open: boolean;
  onClose?: () => void;
};

const Menu = ({ open, onClose }: MenuProps) => {
  const ref = useRef(null);
  const lg = useMediaQuery("(min-width: 1100px)");

  const [mode, setMode] = useState(true);

  const handleClickOutside = () => {
    onClose && onClose();
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <>
      {!lg ? (
        <AnimatePresence>
          {open ? (
            <motion.div
              key={2}
              ref={ref}
              initial={{ opacity: 0, y: -1000 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -1000 }}
              className="overflow-y-hidden w-[100vw] fixed mobile top-0 bottom-0 left-0 flex flex-col z-[400] shadow-md shadow-gray-300/60 px-[20px] py-[32px]"
              transition={{ ease: "easeInOut", duration: 0.3 }}
            >
              <div className="w-full flex justify-end" onClick={onClose}>
                <CloseIcon />
              </div>

              <div className="w-full mt-[20px] flex flex-col justify-center  items-start gap-3 mb-[auto]">
                <MenuLink
                  title="Product"
                  href="/#product"
                  onClick={handleClickOutside}
                />

                <MenuLink
                  title="How it works"
                  href="/#howitworks"
                  onClick={handleClickOutside}
                />

                <MenuLink
                  title="Solutions"
                  href="/#solutions"
                  onClick={handleClickOutside}
                />

                <MenuLink
                  title="Company"
                  href="/about-us"
                  target="_blank"
                  onClick={handleClickOutside}
                />

                <MenuLink
                  title="Careers"
                  target="_blank"
                  href="https://jobs.ashbyhq.com/wokelo-ai"
                  // href="https://darkened-concrete-2aa.notion.site/Open-Positions-at-Wokelo-423cb749eeee4c61ab663202f64144dc"
                  // href="/careers"
                  onClick={handleClickOutside}
                />
              </div>

              <div className="w-full flex justify-center opacity-[10%]">
                <img
                  src="/logo_black.svg"
                  alt="Hero"
                  className="z-30 object-scale-down w-[108px] h-auto"
                />
              </div>

              <div className="flex items-center justify-center mt-[auto]">
                <a
                  href="https://app.wokelo.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${"text-w-black"} mr-8 font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
                >
                  {/* <Button onClick={()=>tracker('Login Clicked')}> */}
                  Login
                  {/* </Button> */}
                </a>

                <a
                  href="/request-access"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    onClick={() => tracker("Start free trial clicked")}
                    variant="outlined" //"primary" for light mode
                    className="w-[170px] h-[50px] flex items-center text-[16px]"
                  >
                    <p>Get Started</p>
                  </Button>
                </a>
              </div>

              {/* <Link
                to="https://app.wokelo.ai/request-access"
                className="w-full mb-[14px]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="primary"
                  className="w-full text-[14px] h-[40px]"
                >
                  Get started
                </Button>
              </Link>

              <Link
                to="https://app.wokelo.ai"
                className="w-full mb-[14px]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="outlined"
                  className="w-full text-[14px] h-[40px]"
                >
                  Login
                </Button>
              </Link> */}

              {/* <span className="flex-1"></span>

              <p className="text-[14px] leading-[20px] font-normal text-w-gray text-center w-full">
                © 2025 Wokelo AI | Wokelo
                <br />
                Inc. All rights reserved
              </p> */}
            </motion.div>
          ) : null}
        </AnimatePresence>
      ) : null}
    </>
  );
};

export default Menu;
