import { useEffect } from "react";

const RedirectToAcg = () => {
  useEffect(() => {
    window.location.href="https://signup.wokelo.ai/wokelo-for-investors/acg-dealmax/";
  }, []);

  return null;
};

export default RedirectToAcg;
