import { animate, AnimationPlaybackControls, motion, useAnimationControls, useMotionValue } from "framer-motion"
import { useEffect, useRef, useState } from "react";
import useMeasure from "../../utils/useMeasure";
import { useMediaQuery } from "@mui/material";

const logos = [
    {
        src: "/tata.svg",
        name: "Tata"
    }, {
        src: "/draper.svg",
        name: "Draper"
    },
    {
        src: "/ey.svg",
        name: "EY"
    }, {
        src: "/google.svg",
        name: "Google"
    }, {
        src: "/kpmg.svg",
        name: "KPMG"
    },
    {
        src: "/insight.svg",
        name: "Insight"
    }, {
        src: "/guggenheim.svg",
        name: "Guggenheim"
        
    }, {
        src: "/siemens.svg",
        name: "Siemens"
    }, {
        src: "/premji.svg",
        name: "Premji"
    }, {
        src: "/berkshire.svg",
        name: "Berkshire"
    }
]

export default function TrustedBy() {
    const isMobile = useMediaQuery('(max-width:700px)');
    const [ref, { width, height }] = useMeasure()
    const [duration] = useState(100)
    const xTranslation = useMotionValue(0)
    const [mustFinish, setMustFinish] = useState(false)
    const [rerender, setRerender] = useState(false)

    useEffect(() => {
        let controls: AnimationPlaybackControls
        const finalPos = -width / 2
        if (mustFinish) {
            controls = animate(xTranslation, [xTranslation.get(), finalPos], {
                duration: duration * (1 - xTranslation.get() / finalPos),
                onComplete: () => {
                    setMustFinish(false)
                    setRerender(!rerender)
                },
                ease: "linear",
            })
        } else {
            controls = animate(xTranslation, [0, finalPos], {
                repeat: Infinity,
                repeatType: "loop",
                duration,
                ease: "linear",
                repeatDelay: 0,
            })
        }
        return () => controls.stop()
    }, [width, xTranslation, duration, rerender])

    return (
        <>
            {isMobile ?
                <section className="pt-7 px-4 md:px-0 md:pt-5 pb-5 space-y-2 flex flex-col bg-clean">
                    <h2 className="text-sm font-medium items-center justify-center text-carbon py-2 border-carbon">
                        Trusted by industry leaders
                    </h2>
                    <div className={`w-fit flex flex-row justify-start items-center ipadGrid gap-4 sm:gap-8 xl:gap-11 overflow-hidden flex-wrap mt-2`}>
                        {[...logos].map((logo, idx) => (
                            <div key={idx} className="p-[2.5px]">
               
                                    <img
                                        src={`/ticker${logo.src}`}
                                        alt={logo.name}
                                        className="w-20 h-[25px] md:h-auto "
                                    />
                            </div>
                        ))}
                    </div>
                </section>
                :
                <section className="pt-5 md:pt-[15px] pb-[15px] flex w-full mx-auto items-center bg-white-bg">
                    <h2 className="text-xl font-medium items-center justify-center text-carbon  pr-10 ml-8 border-r border-carbon">
                        Trusted by <span className="inline md:block md:whitespace-nowrap">industry leaders</span>
                    </h2>

                    <div className="relative overflow-hidden w-full" style={{ height }}>
                        <div className="absolute z-10" style={{ height, background: "linear-gradient(90deg, rgba(256,256,256,1) 0%, rgba(255,255,255,0) 15%, rgba(255,255,255,0) 85%, rgba(256,256,256,1) 100%)" }} />

                        <motion.div
                            ref={ref}
                            className="absolute flex items-center"
                            style={{
                                x: xTranslation,

                            }}
                        >

                            {[...logos, ...logos, ...logos, ...logos].map((logo, idx) => (
                                <div key={idx} className="relative px-5 lg:px-10 xl:px-16 ">
                                    <div className="w-max">
                                        <img
                                            src={`/ticker${logo.src}`}
                                            alt={logo.name}
                                            style={{
                                                imageRendering: "crisp-edges",
                                                transform: "translateZ(0)"
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </motion.div>
                    </div>
                </section>
            }
        </>
    );
}