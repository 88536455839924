import React from 'react'
import Paragraph from '../Paragraph'

function TrustedBySolutions({ logos }: { logos: string[] }) {
    return (
        <div
            className={`flex justify-center `}
        >
            <div className="w-full flex flex-col gap-3 lg:flex-row items-center justify-between">
                <Paragraph
                    className={`hidden lg:block text-carbon text-base font-aeonik whitespace-nowrap font-medium`}
                >
                    Leading firms trust <br /> Wokelo to generate alpha
                </Paragraph>

                <Paragraph
                    className={`block lg:hidden text-carbon text-base font-aeonik whitespace-nowrap !text-left w-full`}
                >
                    Trusted by industry leaders
                </Paragraph>
                <div
                    className={`w-fit flex flex-row justify-start items-center ipadGrid overflow-hidden flex-wrap`}
                >
                    {
                        logos.map((logo) => {
                            return <div className={"mr-6 mt-6 sm:m-4 xl:m-9"} key={logo}>
                                <picture>
                                    <source
                                        srcSet={logo}
                                        type="image/webp"
                                    />
                                    <img
                                        src={logo}
                                        alt="776"
                                        className="z-30 object-scale-down h-[25px] md:h-auto my-auto"
                                    />
                                </picture>
                            </div>
                        })
                    }

                </div>

            </div>
        </div>
    )
}

export default TrustedBySolutions