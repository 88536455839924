import "./App.css";
import Home from "./pages/Home";
import ScrollToTop from "./pages/ScrollToTop";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./core/components/Layout";
import PrivacyPolicyPage from "./pages/privacy-policy";
import TermsAndConditionsPage from "./pages/terms-and-conditions";
import { Navigate } from 'react-router-dom';
import AboutUsPage from "./pages/About";
import { s } from "./core/components/Home/utils";
import RequestAccess from "./pages/RequestAccess";
import RequestAccessPage from "./pages/RequestAccess";
import RequestAccessEvCharging from "./pages/RequestAccessEvCharging";
// import ThankYou from "./core/components/RequestAccess/ThankYou";
import ThankYouEvCharging from "./core/components/RequestAccessEvCharging/ThankYou";
import { useEffect } from "react";
import Conflunece from "./pages/Confluence";
import TradedVC from "./pages/tradedvc";
import Hotjar from '@hotjar/browser';
import CampaignRequestAccess from "./core/components/dyanamicCampaign/RequestAccess";
import CampaignThankYou from "./core/components/dyanamicCampaign/ThankYou";
import CareersPage from "./pages/Career";
import RequestConference from "./pages/RequestConference";
import PrivateEquity from "./pages/PrivateEquity";
import KpmgDas from "./pages/KpmgDas";
import VentureCapital from "./pages/VentureCapital";
import AcgDealmaxAccess from "./core/components/AcgDealMax/AcgAccess";
import Blog from "./pages/Blog";
import Error404 from "./pages/Error404";
import LegalPages from "./pages/LegalPages";
import HomePage from "./pages/HomePage";
import SolutionsPagePE from "./pages/Solutions/SolutionsPagePE";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import ThankYou from "./pages/ThankYou";
import RedirectToCareers from "./core/components/Redirect/RedirectToCareers";
import SolutionsPageMC from "./pages/Solutions/SolutionsPageMC"
import SolutionsPageIB from "./pages/Solutions/SolutionsPageIB"
import SolutionsPageCS from "./pages/Solutions/SolutionsPageCS"
import SolutionsPageFF from "./pages/Solutions/SolutionsPageFF"
import SolutionsPageVC from "./pages/Solutions/SolutionsPageVC"
import RedirectToPE from "./core/components/Redirect/RedirectToPE";
import RedirectToVC from "./core/components/Redirect/RedirectToVC";
import RedirectToAcg from "./core/components/Redirect/RedirectToAcg";
import RedirectToKpmg from "./core/components/Redirect/RedirectToKpmg";


const REACT_APP_HOTJAR_SITEID = process.env.REACT_APP_HOTJAR_SITEID;

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const source = urlParams.get("source");

  useEffect(() => {
    if (!source) {
      return;
    } else if (source) {
      sessionStorage.setItem("source", JSON.stringify(source));
    }
  }, [source]);

  useEffect(() => {
    const siteId = REACT_APP_HOTJAR_SITEID;
    const hotjarVersion = 6;
    if (siteId) {
      Hotjar.init(Number(siteId), hotjarVersion);
    }
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/privacy-policy"
          element={
            <Layout landing={true} className={s.heroPaddings}>
              <PrivacyPolicyPage />
            </Layout>
          }
        />
        {/* <Route
          path="/careers"
          element={
            <Layout landing={true} className={s.heroPaddings}>
              <CareersPage />
            </Layout>
          }
        /> */}
        <Route path="/careers" element={<RedirectToCareers />} />


        <Route
          path="/terms-and-conditions"
          element={
            <Layout landing={true} className={s.heroPaddings}>
              <TermsAndConditionsPage />
            </Layout>
          }
        />
        {/* <Route
          path="/about-us"
          element={
            <Layout landing={false} className={s.heroPaddings}>
              <AboutUsPage />
            </Layout>
          }
        />
 */}

        <Route
          path="/for-private-equity"
          element={
            <SolutionsPagePE />
          }
        />

        <Route
          path="/for-management-consulting"
          element={
            <SolutionsPageMC />
          }
        />

        <Route
          path="/for-investment-banking"
          element={
            <SolutionsPageIB />
          }
        />

        <Route
          path="/for-corporate-strategy"
          element={
            <SolutionsPageCS />
          }
        />

        <Route
          path="/for-fund-of-funds"
          element={
            <SolutionsPageFF />
          }
        />

        <Route
          path="/for-venture-capital"
          element={
            <SolutionsPageVC />
          }
        />

        <Route
          path="/private-equity"
          element={
            // <Layout landing={true} className={s.heroPaddings} headerConfig={{onlyLogo: true}} footerConfig={{theme: 'dark'}}>
            // <PrivateEquity />
            <RedirectToPE/>
            // </Layout>
          }
        />


        <Route
          path="/venture-capital"
          element={
            // <Layout landing={true} className={s.heroPaddings} headerConfig={{onlyLogo: true}} footerConfig={{theme: 'dark'}}>
            // <VentureCapital />
            <RedirectToVC/>
            // </Layout>
          }
        />

        <Route
          path="/acg-dealmax"
          element={
            // <Layout landing={true} className={s.heroPaddings} headerConfig={{onlyLogo: true}} footerConfig={{theme: 'dark'}}>
            // <AcgDealmaxAccess />
            <RedirectToAcg/>
            // </Layout>
          }
        />

        {/* <Route
          path="/insights/female-founders-vc-funding"
          element={
            <Layout landing={true} className={s.heroPaddings} hideFooter>
              <Blog />
            </Layout>
          }
        /> */}

        <Route
          path="/kpmg-das"
          element={
            // <Layout landing={true} className={s.heroPaddings} headerConfig={{onlyLogo: true}} footerConfig={{theme: 'dark'}}>
            // <KpmgDas />
            <RedirectToKpmg/>
            // </Layout>
          }
        />

        <Route path="/request-access" element={<RequestAccessPage />} />
        <Route path="/request-access/download/:source" element={<RequestAccessEvCharging />} />
        <Route path="/thank-you/download/:source" element={<ThankYouEvCharging />} />
        <Route path="/request-access/campaign/:id" element={<CampaignRequestAccess />} />
        <Route path="/thank-you/campaign/:id" element={<CampaignThankYou />} />
        <Route path="/confluence" element={<Conflunece />} />
        <Route path="/tradedvc" element={<TradedVC />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/request-conference" element={<RequestConference />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/legals" element={<LegalPages />} />
        <Route path="/home-page" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
