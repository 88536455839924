import React from "react";
import Paragraph from "../Paragraph";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Input from "../InputV2";

type Props = {};

const HumanProductivity = React.forwardRef((props: Props, ref?: any) => {
    const matches = useMediaQuery("(min-width:1300px)");
    const isMobile = useMediaQuery('(max-width:700px)');
    const [email, setEmail] = useState("");

    return (
        <section id="soc2" className="relative bg-[#222528]  p-5 ">
            <div className={`container mx-auto flex ${isMobile ? 'flex-col' : ''} justify-between py-6 md:py-14 mt-6`}>

                <div className="w-full grid grid-cols-1">
                    <div className="flex flex-col">
                        <div className="w-full flex justify-between  items-start  gap-4">
                            <h1 className="text-clean font-medium text-3xl md:text-6xl max-w-[669px]">
                                Amplify human productivity
                            </h1>
                        </div>
                        <div className="mt-[15px] space-y-6 mb-10">
                            <Paragraph className="w-[100%] md:w-8/12 text-clean/80 text-sm md:text-lg">
                                Say goodbye to mundane tasks and hello to deep insights. Focus on decision-making
                                while Wokelo does the heavy lifting. Perform weeks of work in minutes.
                            </Paragraph>
                        </div>
                        <Input
                            ref={ref}
                            className="relative flex items-end mt-4 bg-[#222528] justify-between w-full max-w-sm p-3 border border-[#757E8780] rounded-md"
                            inputClassName="w-full px-1 md:px-3 py-1 bg-[#222528] font-roboto focus:outline-none text-xs md:text-[16px] text-ash focus:ring-0  rounded-md"
                            buttonClass='absolute  right-1.5 top-1.5 bottom-1.5 px-2 font-roboto-mono md:px-3 text-[10px] md:text-[14px] bg-nano-light text-carbon font-medium rounded-[4px] flex items-center justify-center'
                            placeholder="Your e-mail address"
                            variant="nano"
                            value={email}
                            isMobile={isMobile}
                            buttonText={["BOOK A DEMO", "BOOK A DEMO"]}
                            onInput={(e) => {
                                setEmail(e.currentTarget.value);
                            }}
                        />

                    </div>

                    <div className={` flex w-full ${isMobile ? 'flex-col' : ''} items-center mt-6 my-8 md:my-2  md:mt-20 `}>
                        <div className="w-full flex ">
                            <div className=" flex flex-col mr-7">
                                <picture>
                                    <img
                                        src="HumanProd/10xfaster.svg"
                                        alt="Hero"
                                        className="z-30 object-scale-down w-[120px] md:w-[200px] h-auto my-3"
                                    />
                                </picture>
                                <p className="text-[#8B8D8E] text-[10px] md:text-xs mt-2">With Wokelo</p>
                            </div>
                            <div className=" flex flex-col justify-end items-end">
                                <picture className="border-l border-l-[#F5F5F533] pl-7">
                                    <img
                                        src="HumanProd/4-5weeks.svg"
                                        alt="Hero"
                                        className=" w-[50px] lg:w-[85px] h-auto my-3"
                                    />
                                    <p className="text-[#8B8D8E] text-[9px] mt-2 md:text-sm whitespace-nowrap">Current manual processes</p>
                                </picture>

                            </div>

                        </div>
                    </div>

                </div>

                <div className={`w-full md:w-[60%] flex flex-wrap flex-col gap-7 items-end`}>

                    <div className="w-full  ">
                        <div className="w-full flex flex-col justify-start items-start gap-4 md:gap-5">
                            <div className="flex flex-row mt-6 items-center">
                                <img
                                    src={"HumanProd/Alarm.svg"}
                                    alt=""
                                />
                                <h1 className="text-[#EFEFEF] mx-2.5 text-lg md:text-xl">Hyper-Accelerated Insights </h1>

                            </div>
                            <div>
                                <p className="text-mint/60 pb-2 text-base md:text-md">Dive deep into data at record speeds, by unearthing valuable insights
                                    that traditionally took weeks to uncover
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="bg-gradient-to-l from-gradient-light to-gradient-dark w-full h-[1px] border-0" />


                    <div className="w-full  ">
                        <div className="w-full flex flex-col justify-start items-start gap-4 md:gap-5">
                            <div className="flex flex-row mt-6 items-center">
                                <img
                                    src={"HumanProd/Human-like.svg"}
                                    alt=""
                                />
                                <h1 className="text-[#EFEFEF] mx-2.5 text-lg md:text-xl"> Human-Like Synthesis </h1>

                            </div>
                            <div>
                                <p className="text-mint/60 pb-5 text-base md:text-md">Experience the pinnacle of AI, as Wokelo processes complex information with nuance,
                                    eliminating the manual grunt work
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="bg-gradient-to-l from-gradient-light to-gradient-dark w-full h-[1px] border-0" />

                    <div className="w-full  ">
                        <div className="w-full flex flex-col justify-start items-start gap-4 md:gap-5">
                            <div className="flex flex-row mt-6 items-center">
                                <img
                                    src={"HumanProd/Deliverables.svg"}
                                    alt=""
                                />
                                <h1 className="text-[#EFEFEF] mx-2.5 text-lg md:text-xl"> Refined Deliverables </h1>

                            </div>
                            <div>
                                <p className="text-mint/60 pb-5 text-base md:text-md">Instantly receive polished, high-quality outputs tailored to your specifications,
                                    ensuring you're always presentation-ready
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr className="bg-gradient-to-l from-gradient-light to-gradient-dark w-full h-[1px] border-0" />
                    <div className="w-full  ">
                        <div className="w-full flex flex-col justify-start items-start gap-4 md:gap-5">
                            <div className="flex flex-row mt-6 items-center">
                                <img
                                    src={"HumanProd/Integration.svg"}
                                    alt=""
                                />
                                <h1 className="text-[#EFEFEF] mx-2.5 text-lg md:text-xl"> Seamless Source Integration </h1>

                            </div>
                            <div>
                                <p className="text-mint/60 pb-5 text-base md:text-md">Handpick your preferred data sources, and let Wokelo
                                    produce comprehensive analysis with precise citations
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
});

export default HumanProductivity;
