import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import tracker from "../hooks/useAnalyticsEventTracker";
import MenuIcon from "../icons/Menu";
import Button from "./Button";
import IconButton from "./IconButton";
import Menu from "./Menu";
import { useMediaQuery } from "@mui/material";

type Props = { landing?: any; className?: any; addPaddingRight?: boolean, onlyLogo?: boolean };

const Navbar = ({
  landing = false,
  className = "",
  addPaddingRight = false,
  onlyLogo = false
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const [mode, setMode] = useState(true);
  const matches = useMediaQuery("(min-width:1100px)");

  useEffect(() => {
    document.body.style.overflowY = menuOpen ? "hidden" : "scroll";
  }, [menuOpen]);

  return (
    //change bg at time of animation
    <div
      className={`h-[120px] z-50 py-[30px] md:py-[40px] w-full flex ${addPaddingRight
        ? "pr-[25px] sm:pr-[25px] md:pr-[25px] lg:pr-[120px] xl:pr-[120px] 2xl:pr-[120px]"
        : ""
        } ${className}`}
    >
      <div className={`w-full flex flex-row items-center justify-between`}>
        <a href="/" className="cursor-pointer flex flex-col gap-1">
          <picture>
            <source
              srcSet={landing ? "/logo_black.svg" : "/logo_white.svg"}
              type="image/webp"
            />
            <img
              src={landing ? "/logo_black.svg" : "/logo_white.svg"}
              alt="Hero"
              className="z-30 object-scale-down w-[69px] h-auto"
            />
          </picture>
          {location.pathname === "/venture-capital" && <span>Wokelo.ai</span>}
        </a>


        {!onlyLogo ? <div
          className={`flex-row items-center ${matches ? "lg:flex xl:flex 2xl:flex" : "hidden"
            }  gap-12 `}
        >
          <div className="flex flex-row items-center gap-8">
            <a
              href="/#product"
              className={`${landing ? "text-w-black" : "text-w-medium-gray"
                } font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
            // onClick={() => {
            //   scrollToSection("product");
            // }}
            >
              Product
            </a>

            <a
              href="/#howitworks"
              className={`${landing ? "text-w-black" : "text-w-medium-gray"
                } font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
            // onClick={() => {
            //   scrollToSection("how-it-works");
            // }}
            >
              How it works
            </a>

            <a
              href="/#solutions"
              className={`${landing ? "text-w-black" : "text-w-medium-gray"
                } font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
            // onClick={() => {
            //   window.scrollTo({
            //     top: document?.getElementById("solutions")?.offsetTop,
            //     behavior: "smooth",
            //   });
            // }}
            >
              Solutions
            </a>

            <a
              href="/about-us"
              target="_blank"
              className={`${landing ? "text-w-black" : "text-w-medium-gray"
                } font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
            >
              Company
            </a>

            <a
              // href="https://darkened-concrete-2aa.notion.site/Open-Positions-at-Wokelo-423cb749eeee4c61ab663202f64144dc"
              // href="/careers"
              href="https://jobs.ashbyhq.com/wokelo-ai"
              target="_blank"
              className={`${landing ? "text-w-black" : "text-w-medium-gray"
                } font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
            >
              Careers
            </a>
          </div>

          <div className="flex flex-row items-center gap-8 ml-0 lg:ml-[28px] xl:ml-[50px] 2xl:ml-[50px]">
            <a
              href="https://app.wokelo.ai"
              target="_blank"
              rel="noopener noreferrer"
              className={`${landing ? "text-w-black" : "text-w-light-white"
                } font-[500] text-[16px] leading-[16px] hover:text-w-light-gray transition-all`}
            >
              {/* <Button onClick={()=>tracker('Login Clicked')}> */}
              Login
              {/* </Button> */}
            </a>

            <a href="/request-access" rel="noopener noreferrer">
              {mode ? (
                <Button
                  onClick={() => tracker("Start free trial clicked")}
                  variant="outlined" //"primary" for light mode
                  className="w-[170px] h-[50px] flex items-center text-[16px]"
                >
                  <p>Get Started</p>
                </Button>
              ) : (
                <Button
                  onClick={() => tracker("Start free trial clicked")}
                  variant="primary" //"primary" for light mode
                  className="w-[170px] h-[50px] flex items-center text-[16px]"
                >
                  <p>Get Started</p>
                </Button>
              )}
            </a>
          </div>
        </div> : <></>}

        {!onlyLogo ? <IconButton
          className={`${matches ? "hidden" : "flex"} `}
          variant={"primary"}
          onClick={() => setMenuOpen(true)}
        >
          <MenuIcon />
        </IconButton> : null}
      </div>

      <Menu open={menuOpen} onClose={() => setMenuOpen(false)} />
    </div>
  );
};

export default Navbar;
