function UseCases({ title = '', usecases = [] }: { title, usecases }) {
    return (
        <div className='relative  p-5 md:p-14 bg-[#EFEFEF] py-16'>
            <h2 className='text-carbon font-aeonik text-[32px] lg:text-5xl font-normal leading-[90%] capitalize'>
                {title}
            </h2>

            <div className='grid lg:grid-cols-2 gap-3 mt-12'>
                {
                    usecases.map((usecase) => {
                        return <div className="p-[1.5px] md:hover:bg-gradient-to-b from-gradient-light to-gradient-dark" key={usecase.title}><div className='bg-white h-full border-gradient p-[30px]' >
                            <h3 className='text-carbon font-aeonik text-[21px] lg:text-2xl font-medium'>
                                {usecase.title}
                            </h3>


                            <div className='grid lg:grid-cols-2 mt-8'>
                                <div className='col-span-1 lg:pr-4 lg:border-r'>
                                    <div className='bg-ash px-1.5 py-1 text-shade font-roboto-mono text-xs uppercase font-medium w-max max-w-full break-word mb-8'>
                                        {usecase.left.badge}
                                    </div>
                                    <div className='flex flex-col gap-y-3'>
                                        {
                                            usecase.left.points.map(p => {
                                                return <p key={p} className='text-carbon text-base font-roboto font-normal leading-[130%] opacity-80'>
                                                    {p}
                                                </p>
                                            })
                                        }
                                    </div>
                                </div>

                                <div className='col-span-1 lg:pl-4 border-t lg:border-none mt-8 lg:mt-0 pt-8 lg:pt-0'>
                                    <div className='bg-ash px-1.5 py-1 text-shade font-roboto-mono text-xs uppercase font-medium w-max max-w-full break-word mb-8'>
                                        {usecase.right.badge}
                                    </div>
                                    <div className='flex flex-col gap-y-3'>
                                        {
                                            usecase.right.points.map(p => {
                                                return <p key={p} className='text-carbon text-base font-roboto font-normal leading-[130%] opacity-80'>
                                                    {p}
                                                </p>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default UseCases