import React from 'react';
import { MetaTagProps } from '../../utils/meta';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface PageProps {
    metaProps: MetaTagProps;
}

const MetaTags: React.FC<PageProps> = ({ metaProps }) => {
    return (
        <Helmet>
            <title>{metaProps.title}</title>
            <meta name="description" content={metaProps.description} />
            <meta property="og:title" content={metaProps.title} />
            <meta property="og:description" content={metaProps.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://wokelo.ai/${metaProps.pageName.toLowerCase().replace(/\s+/g, '-')}`} />
            <meta property="og:image" content="https://wkemails.blob.core.windows.net/fe-application/link-preview.svg" />
            {window.location.hostname === "site-dev.wokelo.ai" && <meta name="robots" content="noindex, nofollow" />}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaProps.title} />
            <meta name="twitter:description" content={metaProps.description} />
            <meta name="twitter:image" content="https://wkemails.blob.core.windows.net/fe-application/link-preview.svg" />
        </Helmet>
    );
};
export default MetaTags