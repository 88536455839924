import { Link } from "react-router-dom";
import Heading2 from "../core/components/Heading2";
import Heading3 from "../core/components/Heading3";
import Heading4 from "../core/components/Heading4";
import Paragraph from "../core/components/Paragraph";
import tracker from "../core/hooks/useAnalyticsEventTracker";
import s from "../core/styles/general.styles";

import React, { useEffect } from "react";
import Paragraph1 from "../core/components/Paragraph1";
import Heading31 from "../core/components/Heading31";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section
        className={`pt-6 sm:pt-6 md:pt-10 lg:pt-10 xl:pt-10 2xl:pt-10 pb-10 ${s.sectionPaddings}`}
      >
        <Heading2 className="mb-2 font-bold">PRIVACY NOTICE</Heading2>

        <Heading31 className="mb-2 font-bold uppercase">
          1. Agreement to terms
        </Heading31>

        <Paragraph1 className="mb-1">
          This privacy notice for Wokelo Inc. (doing business as Wokelo AI) (
          <b>
            &quot;Wokelo AI,&quot; &quot;we,&quot; &quot;us,&quot; or
            &quot;our&quot;
          </b>
          ), describes how and why we might collect, store, use, and/or share (
          <b>&quot;process&quot;</b>) your information when you use our services
          (<b>&quot;Services&quot;</b>), such as when you: Visit our website at{" "}
          <Link to="https://wokelo.ai" className="text-w-purple">
            https://wokelo.ai
          </Link>
          , or any website of ours that links to this privacy notice Engage with
          us in other related ways, including any sales, marketing, or events.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Questions or concerns?</b> Reading this privacy notice will help
          you understand your privacy rights and choices. If you do not agree
          with our policies and practices, please do not use our Services. If
          you still have any questions or concerns, please contact us at
          support@wokelo.ai.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          If you have another written agreement between us and you governing
          your use of Wokelo products or services, the other written agreement
          will control over this privacy notice to the extent of any conflict
          between the two with respect to the use of the Wokelo products and
          services covered by the other written agreement.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          1. WHAT INFORMATION DO WE COLLECT?
        </Heading31>

        <Heading4>Personal information you disclose to us</Heading4>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We collect personal information that you provide to
            us.
          </em>
          <br /> We collect personal information that you voluntarily provide to
          us when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Personal Information Provided by You.</b> The personal information
          that we collect depends on the context of your interactions with us
          and the Services, the choices you make, and the products and features
          you use. The personal information we collect may include the
          following: names, email addresses, job titles, usernames, passwords,
          contact preferences, debit/credit card numbers, contact or
          authentication data, billing addresses
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Sensitive Information.</b> We do not process sensitive information.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Payment Data.</b> We may collect data necessary to process your
          payment if you make purchases, such as your payment instrument number,
          and the security code associated with your payment instrument.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </Paragraph1>

        <Heading4>Information automatically collected</Heading4>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> Some information — such as your Internet Protocol
            (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          The information we collect includes:
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <em>Log and Usage Data.</em> Log and usage data is service-related,
          diagnostic, usage, and performance information our servers
          automatically collect when you access or use our Services and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type,
          and settings and information about your activity in the Services (such
          as the date/time stamps associated with your usage, pages and files
          viewed, searches, and other actions you take such as which features
          you use), device event information (such as system activity, error
          reports (sometimes called &quot;crash dumps&quot;), and hardware
          settings).
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We process your information to provide, improve,
            and administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </b>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>
            To facilitate account creation and authentication and otherwise
            manage user accounts.
          </b>{" "}
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>To save or protect an individual&apos;s vital interest.</b> We may
          process your information when necessary to save or protect an
          individual&apos;s vital interest, such as to prevent harm.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We only process your personal information when we
            believe it is necessary and we have a valid legal reason (i.e.,
            legal basis) to do so under applicable law, like with your consent,
            to comply with laws, to provide you with services to enter into or
            fulfill our contractual obligations, to protect your rights, or to
            fulfill our legitimate business interests.
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>
            If you are located in the EU or UK, this section applies to you.
          </b>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Consent.</b> We may process your information if you have given us
          permission (i.e., consent) to use your personal information for a
          specific purpose. You can withdraw your consent at any time. Click
          here to learn more.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Legal Obligations.</b> We may process your information where we
          believe it is necessary for compliance with our legal obligations,
          such as to cooperate with a law enforcement body or regulatory agency,
          exercise or defend our legal rights, or disclose your information as
          evidence in litigation in which we are involved.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Vital Interests.</b> We may process your information where we
          believe it is necessary to protect your vital interests or the vital
          interests of a third party, such as situations involving potential
          threats to the safety of any person.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <em>
            <b>If you are located in Canada, this section applies to you.</b>
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can withdraw your consent at any
          time.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example: If collection is clearly in the interests of
          an individual and consent cannot be obtained in a timely way For
          investigations and fraud detection and prevention For business
          transactions provided certain conditions are met If it is contained in
          a witness statement and the collection is necessary to assess,
          process, or settle an insurance claim For identifying injured, ill, or
          deceased persons and communicating with next of kin If we have
          reasonable grounds to believe an individual has been, is, or may be
          victim of financial abuse If it is reasonable to expect collection and
          use with consent would compromise the availability or the accuracy of
          the information and the collection is reasonable for purposes related
          to investigating a breach of an agreement or a contravention of the
          laws of Canada or a province If disclosure is required to comply with
          a subpoena, warrant, court order, or rules of the court relating to
          the production of records If it was produced by an individual in the
          course of their employment, business, or profession and the collection
          is consistent with the purposes for which the information was produced
          If the collection is solely for journalistic, artistic, or literary
          purposes If the information is publicly available and is specified by
          the regulations
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We may share information in specific situations
            described in this section and/or with the following third parties.
          </em>
          We may need to share your personal information in the following
          situations:
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Business Transfers.</b> We may share or transfer your information
          in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          5. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We keep your information for as long as necessary
            to fulfill the purposes outlined in this privacy notice unless
            otherwise required by law.
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than six
          (6) months past the termination of the user&apos;s account.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          6. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We aim to protect your personal information through
            a system of organizational and technical security measures.
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          7. DO WE COLLECT INFORMATION FROM MINORS?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> We do not knowingly collect data from or market to
            children under 18 years of age.
          </em>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at support@wokelo.ai.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          8. WHAT ARE YOUR PRIVACY RIGHTS?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> In some regions, such as the European Economic Area
            (EEA), United Kingdom (UK), and Canada, you have rights that allow
            you greater access to and control over your personal information.
            You may review, change, or terminate your account at any time.
          </em>
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. We will consider and act upon any request in accordance
          with applicable data protection laws.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Withdrawing your consent:</b> If we are relying on your consent to
          process your personal information, which may be express and/or implied
          consent depending on the applicable law, you have the right to
          withdraw your consent at any time. However, please note that this will
          not affect the lawfulness of the processing before its withdrawal nor,
          when applicable law allows, will it affect the processing of your
          personal information conducted in reliance on lawful processing
          grounds other than consent.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          <b>Opting out of marketing and promotional communications:</b> You can
          unsubscribe from our marketing and promotional communications at any
          time by replying &quot;STOP&quot; or &quot;UNSUBSCRIBE&quot; to the
          SMS messages that we send. You will then be removed from the marketing
          lists. However, we may still communicate with you — for example, to
          send you service-related messages that are necessary for the
          administration and use of your account, to respond to service
          requests, or for other non-marketing purposes.
        </Paragraph1>

        <Heading4>Account Information</Heading4>

        <Paragraph1 className="mb-1">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </Paragraph1>

        <Paragraph1 className="mb-1">
          Contact us using the contact information provided. Upon your request
          to terminate your account, we will deactivate or delete your account
          and information from our active databases. However, we may retain some
          information in our files to prevent fraud, troubleshoot problems,
          assist with any investigations, enforce our legal terms and/or comply
          with applicable legal requirements. If you have questions or comments
          about your privacy rights, you may email us at support@wokelo.ai.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          9. CONTROLS FOR DO-NOT-TRACK FEATURES
        </Heading31>

        <Paragraph1 className="mb-1">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&quot;DNT&quot;) feature or
          setting you can activate to signal your privacy preference not to have
          data about your online browsing activities monitored and collected. At
          this stage no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not
          currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the
          future, we will inform you about that practice in a revised version of
          this privacy notice.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> Yes, if you are a resident of California, you are
            granted specific rights regarding access to your personal
            information.
          </em>{" "}
          <br />
          California Civil Code Section 1798.83, also known as the &quot;Shine
          The Light&quot; law, permits our users who are California residents to
          request and obtain from us, once a year and free of charge,
          information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names
          and addresses of all third parties with which we shared personal
          information in the immediately preceding calendar year. If you are a
          California resident and would like to make such a request, please
          submit your request in writing to us using the contact information
          provided below.
        </Paragraph1>

        <Paragraph1 className="mb-1">
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          11. DO WE MAKE UPDATES TO THIS NOTICE?
        </Heading31>

        <Paragraph1 className="mb-1">
          <em>
            <b>In Short:</b> Yes, we will update this notice as necessary to
            stay compliant with relevant laws.
          </em>
        </Paragraph1>

        <Paragraph1 className="mb-1">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated &quot;Revised&quot; date and
          the updated version will be effective as soon as it is accessible. If
          we make material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by directly
          sending you a notification. We encourage you to review this privacy
          notice frequently to be informed of how we are protecting your
          information.
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Heading31>

        <Paragraph1 className="mb-1">
          If you have questions or comments about this notice, you may email us
          at support@wokelo.ai or by post to:
          <br />
          Wokelo Inc. <br />
          92 Lenora Street, Seattle, Washington 98121 <br />
          United States
        </Paragraph1>

        <Heading31 className="mt-4 mb-2">
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Heading31>

        <Paragraph1 className="mb-1">
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it.
        </Paragraph1>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
