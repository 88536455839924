import { useNavigate } from "react-router-dom";
import { TopRightArrowIcon } from "../core/icons/icons";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <div className="relative w-full h-screen bg-shade flex flex-col">
      <img
        src="/Error-404/error404.png"
        alt="Error-404"
        className="absolute bottom-0 left-0 w-full h-auto max-h-[92vh] object-contain hidden sm:block"
      />

      <img
        src="/Error-404/error404-small.png"
        alt="Error-404"
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2  max-h-[50%] object-contain sm:hidden "
      />

      <div className="relative flex flex-col items-center text-white text-center font-aeonik px-6  py-40 sm:py-40 md:py-25 lg:py-20 ">
        <h1 className="text-3xl sm:text-3xl md:text-5xl lg:text-5xl ">
          This page could not be found
        </h1>
        <button
          onClick={() => navigate("/")}
          className="mt-6 flex items-center text-nano-light  text-lg"
        >
          BACK TO HOMEPAGE
          <TopRightArrowIcon width={22} height={22} color="#97C8A6" className="ml-1" />
        </button>
      </div>
    </div>
  );
};

export default Error404;
