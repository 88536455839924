import Navbar from '../Navbar'
import FooterNew from '../../core/components/Footer/Footer'
import Challenges from '../../core/components/Solution/Challenges'
import HowItWorks from '../../core/components/Home/HowItWorksV2'
import WhyWokeloSolutions from '../../core/components/Solution/WhyWokeloSolutions'
import UseCases from '../../core/components/Solution/UseCases'
import Discover from '../../core/components/Solution/Discover'
import MetaTags from '../../core/components/Meta/Meta'
import { corporateStrategyMeta } from '../../core/utils/meta'



const Content = {
    challenges: {
        title: <>The challenge of traditional corporate strategy research</>,
        subtitle: <>Reliance on external research vendors, unexpected ad-hoc<br />
            requests, and knowledge silos lead to redundant efforts and<br />
            outdated insights for corporates</>,
        mobilesubtitle: <>Reliance on external research vendors, unexpected ad-hoc
        requests, and knowledge silos lead to redundant efforts and
        outdated insights for corporates</>,
        image: 'Corporate.png',
        points: [
            'Time wasted on manual data gathering instead of strategic decision-making',
            'Sprawling data, disconnected reports, outdated information, and huge database spend',
            'Delayed stakeholder alignment, and missed opportunities for business growth',
        ]
    },

    why: {
        title: <>Wokelo eliminates research bottlenecks and streamlines workflows —
            so corporate teams can focus on high-impact decisions and innovation.</>,
        points: [
            'Access on-demand insights on any industry, company, or topic—curated from thousands of sources with a single click',
            'Extract hyper-specific answers and 360-degree insights from vast, siloed data repositories—with rapid turnaround and no manual effort',
            'Automate tailored research outputs for diverse teams and projects, customized by stakeholder needs, use cases, and research scope',
        ],
        logos: ['/ticker/siemens-solutions.svg', '/ticker/google-solutions.svg', '/ticker/microsoft-solutions.svg', '/ticker/NextEra-solutions.svg', '/ticker/tata-solutions.svg']
    },
    usecases: {
        title: 'Top Wokelo Use Cases for Corporates',
        usecases: [
            {
                title: 'Faster turnaround on market intel',
                left: {
                    badge: 'Ad-hoc Research Tasks',
                    points: [
                        'Trend identification',
                        'Decision support',
                        'Market dynamics '
                    ]
                },
                right: {
                    badge: 'Recurring reporting',
                    points: [
                        'Daily briefings',
                        'Quarterly financial summaries',
                        'Weekly dealmaking trends',
                    ]
                }
            },
            {
                title: 'Accelerated diligence for CorpDev',
                left: {
                    badge: 'Day-0 Fact pack',
                    points: [
                        'Company research',
                        'Competitive benchmarking',
                        'Management strength'
                    ]
                },
                right: {
                    badge: 'Deep dive analysis',
                    points: [
                        'Data room analysis',
                        'Financial & operational insights',
                        'Business case inputs',
                    ]
                }
            },
            {
                title: 'Targeted business development',
                left: {
                    badge: 'Faster Lead Qualification',
                    points: [
                        'Curated company lists',
                        'Key metrics & indicators',
                        'M&A activity, partnerships'
                    ]
                },
                right: {
                    badge: 'Effective Sales Pitches',
                    points: [
                        'Pre-meeting briefs / account intel',
                        'Expert insights',
                        'Gaps & opportunities',
                    ]
                }
            },
            {
                title: 'Actionable input for product & GTM',
                left: {
                    badge: 'Product Strategy',
                    points: [
                        'Launches & announcements',
                        'Product capabilities & IP',
                        'User reviews & sentiment'
                    ]
                },
                right: {
                    badge: 'Product Marketing',
                    points: [
                        'Go-to-market strategy',
                        'Targeted messaging',
                        'Online presence & popularity',
                    ]
                }
            }
        ]
    }
}

function SolutionsPageCS() {

    return (
        <div className='relative !bg-white overflow-x-hidden' >
            <MetaTags metaProps={corporateStrategyMeta} />

            <div className='relative'>
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"

                />

                <Navbar className='!bg-none' />
                <div className='w-3/4 mx-auto text-center min-h-[280px] md:min-h-[500px] flex justify-center items-center'>
                    <h1 className='text-carbon text-[40px] lg:text-[80px] font-semibold font-aeonik leading-[40px] lg:leading-[72px]'>
                        Wokelo for Corporate Strategy
                    </h1>
                </div>
            </div>
            <Challenges {...Content.challenges} />
            <WhyWokeloSolutions {...Content.why} />
            <Discover />
            <UseCases {...Content.usecases} />
            <HowItWorks />
            <FooterNew />
        </div>
    )
}

export default SolutionsPageCS