// components/CalendlyWidget.js
import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Api } from "../hooks/Api";

const CalendlyWidget = ({ open = false, setOpen, userDetails }) => {
  const [isFinal, setIsFinal] = useState(false);
  const cancelButtonRef = useRef(null);

  useCalendlyEventListener({
    onEventScheduled: function get(e) {
      if (e) {
        setIsFinal(true);
        if(userDetails) {
          if(userDetails?.username) { 
            Api.slotBookedNotification(userDetails).then((res)=>{})
          }
        }
      } else {
        setIsFinal(false);
      }
    },
  });

  const handleClose = () => {
    setOpen(isFinal);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1002]"
        initialFocus={cancelButtonRef}
        // onClose={handleClose}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[1001] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:m-4 md:w-[1200px] max-sm:w-full">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <InlineWidget
                      url="https://calendly.com/wokelo-sales/45min"
                      // url="https://calendly.com/deeppatel-wokelo/30min"
                      styles={{
                        // Customize the widget styles as needed
                        width: "100%",
                        height: "700px",
                      }}
                    />
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleClose}
                    ref={cancelButtonRef}
                  >
                    {isFinal ? "Ok" : "Skip"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CalendlyWidget;
