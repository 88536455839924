import Navbar from '../Navbar'
import FooterNew from '../../core/components/Footer/Footer'
import Challenges from '../../core/components/Solution/Challenges'
import HowItWorks from '../../core/components/Home/HowItWorksV2'
import WhyWokeloSolutions from '../../core/components/Solution/WhyWokeloSolutions'
import UseCases from '../../core/components/Solution/UseCases'
import Discover from '../../core/components/Solution/Discover'
import MetaTags from '../../core/components/Meta/Meta'
import { managementConsultingMeta } from '../../core/utils/meta'


const Content = {
    challenges: {
        title: <>The challenge of traditional management consulting research</>,
        subtitle: <>Knowledge bottlenecks and silos, fragmented ad-hoc<br />
            requests, and dependency on offshore teams leads to <br />
            redundancy and inefficiency.</>,
        mobilesubtitle: <>Knowledge bottlenecks and silos, fragmented ad-hoc
        requests, and dependency on offshore teams leads to 
        redundancy and inefficiency.</>,
        image: 'Management.png',
        points: [
            'Overloaded consultants stuck in research and manual deliverable creation instead of strategic challenges',
            'Burnt-out project teams, overreliance on offshore capability centers, and bloated data subscriptions',
            'Missed opportunities to deepen client relationships and drive new business growth',
        ]
    },

    why: {
        title: <>Wokelo streamlines end-to-end research workflows, eliminates inefficiencies, and enables consulting teams to focus on high-impact analysis, client engagement, and business expansion.</>,
        points: [
            'Access on-demand insights on any industry, company, or topic—curated from thousands of sources in a single click',
            'Extract hyper-specific answers and 360-degree insights from vast, siloed data repositories—with rapid turnaround and no manual effort',
            'Automate tailored research outputs for diverse teams and projects, customized by stakeholder, use case, and scope',
        ],
        logos: ['/ticker/ey-solutions.svg', '/ticker/kpmg-solutions.svg', '/ticker/Ycp-solutions.svg', '/ticker/siemens-solutions.svg', '/ticker/tata-solutions.svg']
    },
    usecases: {
        title: 'Top Wokelo Use Cases for Management Consulting',
        usecases: [
            {
                title: 'Accelerated due diligence for M&A',
                left: {
                    badge: 'Company Fact packs',
                    points: [
                        'Company research',
                        'Competitive positioning',
                        'Management strength'
                    ]
                },
                right: {
                    badge: 'Deep dive analysis',
                    points: [
                        'Data room analysis',
                        'Financial & operational insights',
                        'Business case inputs',
                    ]
                }
            },
            {
                title: 'Minimized repetitive market research',
                left: {
                    badge: 'Ad-hoc Research Tasks',
                    points: [
                        'Trend identification',
                        'Decision support',
                        'Market dynamics'
                    ]
                },
                right: {
                    badge: 'Recurring reporting',
                    points: [
                        'Daily briefings',
                        'Quarterly financial summaries',
                        'Weekly dealmaking trends',
                    ]
                }
            },
            {
                title: 'Stronger, informed business development',
                left: {
                    badge: 'Faster lead qualification',
                    points: [
                        'Curated company lists',
                        'Key metrics & indicators',
                        'M&A activity, partnerships'
                    ]
                },
                right: {
                    badge: 'Effective Sales Pitches',
                    points: [
                        'Pre-meeting briefs',
                        'Expert insights',
                        'Gaps & opportunities',
                    ]
                }
            },
            {
                title: 'Optimized costs of data subscriptions',
                left: {
                    badge: 'Integrate multiple sources',
                    points: [
                        'Pre-integrated paid datasets',
                        'Bring existing subscriptions',
                        '50+ app integrations'
                    ]
                },
                right: {
                    badge: 'Frictionless workflows',
                    points: [
                        'Report generation APIs',
                        'Data stream APIs',
                        'Configurable templates',
                    ]
                }
            }
        ]
    }
}

function SolutionsPageMC() {

    return (
        <div className='relative !bg-white overflow-x-hidden' >
            <MetaTags metaProps={managementConsultingMeta} />

            <div className='relative'>
                <img
                    src="/solution-bg.svg"
                    alt="Hero"
                    className="w-full h-full my-auto absolute left-20 top-0 opacity-[.15] scale-[2] object-cover -rotate-6"

                />

                <Navbar className='!bg-none' />
                <div className='w-3/4 mx-auto text-center min-h-[280px] md:min-h-[500px] flex justify-center items-center'>
                    <h1 className='text-carbon text-[40px] lg:text-[80px] font-semibold font-aeonik leading-[40px] lg:leading-[72px]'>
                        Wokelo for Management Consulting
                    </h1>
                </div>
            </div>
            <Challenges {...Content.challenges} />
            <WhyWokeloSolutions {...Content.why} />
            <Discover />
            <UseCases {...Content.usecases} />
            <HowItWorks />
            <FooterNew />
        </div>
    )
}

export default SolutionsPageMC