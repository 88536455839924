import React from 'react'
import { useMediaQuery } from 'usehooks-ts';
import DesktopFooter from '../../../pages/DesktopFooter';
import MobileFooter from '../../../pages/MobileFooter';

function FooterNew() {
    const isMobile = useMediaQuery('(max-width:700px)');

    return (
        <>
            {isMobile ? <MobileFooter /> : <DesktopFooter />}
        </>
    )
}

export default FooterNew